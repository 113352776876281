import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Rating from "react-rating";
import { ReactSortable } from "react-sortablejs";
// import Tooltip from "../common/tooltip";
import MSG, { notifySuccess, notifyError, randomKey } from "../../utils/Helper";
import { startLoader, stopLoader } from "../../store/reducers/generalActions";
import {
  updateQuestionRating,
  updateOptionRating,
  updateIsRequired,
  SaveEditQuestion,
} from "../../services";
import EditBloomTaxanomy from "../editquestions/editBloomTaxanomy";
import SweetAlert from "react-bootstrap-sweetalert";
import { trackEvent } from "../../utils/mixpanel";
import Switch from "react-switch";
import Zoom from "react-medium-image-zoom";
import { Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import styles from "../../styles/qnaResponsive.module.css";
import classNames from "classnames";
import ReadMore from "../common/ReadMore";

class bloomsTexanomay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionRequired: this.props.question.is_required,
      reasonBox: this.props.question.is_required,
      reasonComment:
        this.props.question.comment == "None"
          ? ""
          : this.props.question.comment,
      ques_rating: this.props.question_rating,
      opt_rating: this.props.opt_rating,
      editquestionpopup: false,
      openBoxDefault: false,
      alert: null,
      neutralize: false,
      disable: false,
      checked: this.props.question.is_required ? false : true,
      accordionText: "View Answer",
    };
  }

  async componentDidMount() {
    if (this.props.disable) {
      this.setState({
        neutralize: !this.state.neutralize,
        disable: !this.state.disable,
      });
    }
  }

  handleRatingQuestion = async (value) => {
    // update the rating

    if (!this.state.neutralize) {
      this.props.dispatch(startLoader());
      const req = {
        questionId: this.props.question.question_id,
        questionRating: value,
      };
      try {
        const { data: res } = await updateQuestionRating(req);
        // console.log(res);
        if (res.success == true) {
          notifySuccess(res.message);
          this.setState({
            ques_rating: value,
          });
        } else {
          notifyError(res.message);
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 422) {
          const { data } = err.response;
          notifyError(data.message);
        } else {
          notifyError(MSG.commonApiError);
        }
      }
      this.props.dispatch(stopLoader());
    }
  };

  handleRatingOptions = async (value) => {
    // update the rating
    if (!this.state.neutralize) {
      this.props.dispatch(startLoader());
      const req = {
        questionId: this.props.question.question_id,
        optionRating: value,
      };
      try {
        const { data: res } = await updateOptionRating(req);
        // console.log(res);
        if (res.success == true) {
          notifySuccess(res.message);
          this.setState({
            opt_rating: value,
          });
        } else {
          notifyError(res.message);
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 422) {
          const { data } = err.response;
          notifyError(data.message);
        } else {
          notifyError(MSG.commonApiError);
        }
      }
      this.props.dispatch(stopLoader());
    }
  };

  handleisRequiredChange = ({ currentTarget: input }) => {
    this.setState({
      questionRequired: input.value,
      reasonBox: input.value,
    });
  };

  handleChangeComment = ({ currentTarget: input }) => {
    this.setState({
      reasonComment: input.value,
    });
  };

  handleCloseReasonBox = (e) => {
    this.setState({
      reasonBox: 1,
    });
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  handleisRequiredClick = (val) => {
    this.setState({ checked: val });
    if (!this.state.neutralize) {
      // if ((val == 1 || val == 0) && this.state.questionRequired == 0) {
      // if (val == 0) {
      //   trackEvent("Click on IsRequired - No", {
      //     source: this.props.data.source,
      //   });
      // }

      // update on server silently
      const req = {
        questionId: this.props.question.question_id,
        IsRequired: !val,
        comment: this.state.reasonComment,
      };
      this.saveIsRequired(req);
    } else {
      //for demo delete
      localStorage.setItem(`Q${this?.props?.question?.question_id}`, val);
    }
    if (!this.state.openBoxDefault) {
      this.setState({
        openBoxDefault: true,
        questionRequired: val,
        reasonBox: val,
      });
    } else {
      this.setState({
        questionRequired: val,
        reasonBox: val,
      });
    }
    // }
  };

  saveIsRequired = async (req) => {
    try {
      const { data: res } = await updateIsRequired(req);
      // console.log(res);
      return res;
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }
  };

  handleCommentSave = async () => {
    if (!this.state.reasonComment) {
      notifyError("No comment to save");
      return false;
    }
    const req = {
      questionId: this.props.question.question_id,
      IsRequired: this.state.questionRequired,
      comment: this.state.reasonComment,
    };
    this.props.dispatch(startLoader());
    const res = await this.saveIsRequired(req);
    if (res.success == true) {
      notifySuccess(res.message);
      this.setState({
        reasonBox: 1,
      });
    } else {
      notifyError(res.message);
    }
    this.props.dispatch(stopLoader());
  };

  editbutton = (e) => {
    e.preventDefault();
    if (e.target.name == "edit-button") {
      // // trackEvent("Edit Question Button Clicked", {
      //   source: this.props.data.source,
      // });
    }
    this.setState({
      editquestionpopup: !this.state.editquestionpopup,
    });
  };

  validatemainquestion = (mainQuestion) => {
    var que = mainQuestion;
    var question = que.replace("Ques  :", "");

    if (question.trim() == "") {
      return false;
    }
    if (!question.endsWith("?")) {
      return false;
    }
    return true;
  };

  validateoption = (question) => {
    var opt = question.options;
    var option = opt.map((val) => val.replace("*", ""));

    for (var i = 0; i < option.length; i++) {
      if (option[i].trim() == "") {
        return false;
      }
    }

    return true;
  };

  savechanges = async (question, mainQuestion, optionIndex) => {
    if (
      !this.validatemainquestion(mainQuestion) ||
      !this.validateoption(question)
    ) {
      return false;
    }
    const changedquestion = { ...question };
    // changedquestion.question = [
    //   question.question[0].substring(0, 7) + mainQuestion,
    // ];

    changedquestion.question = mainQuestion;

    if (optionIndex != null) {
      var optionsObj = changedquestion.options;
      var opt = optionsObj.map((val) => val.replace("*", ""));
      opt[optionIndex] = opt[optionIndex] + " *";
      changedquestion.options = opt;
    }

    var que = changedquestion.question;
    var opt = changedquestion.options;
    var queId = changedquestion.question_id;

    var reqData = {
      questionId: queId,
      questions: que,
      options: opt,
      is_taxonomy: true,
    };

    this.props.dispatch(startLoader());
    try {
      const { data: res } = await SaveEditQuestion(reqData);
      if (res.success == true) {
        notifySuccess(res.message);
        this.props.handleQuestionUpdate(changedquestion);
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.props.dispatch(stopLoader());
  };

  handlealert = (question, mainQuestion, optionIndex) => {
    const getalert = (question, mainQuestion, optionIndex) => {
      return (
        <SweetAlert
          showConfirm
          title={
            <div className="swal-title">
              {" "}
              Do you want to save your edited question?
            </div>
          }
          confirmBtnText="Yes"
          confirmBtnStyle={{ fontSize: "16px" }}
          showCancel
          cancelBtnText="No"
          cancelBtnBsStyle={{ fontSize: "16px" }}
          // cancelBtnBsStyle="default"
          confirmBtnBsStyle="success"
          onConfirm={() => {
            this.savechanges(question, mainQuestion, optionIndex);
            this.setState({
              editquestionpopup: !this.state.editquestionpopup,
              alert: null,
            });
          }}
          onCancel={() => {
            this.setState({
              editquestionpopup: !this.state.editquestionpopup,
              alert: null,
            });
          }}
        />
      );
    };

    this.setState({
      alert: getalert(question, mainQuestion, optionIndex),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  // showCategoryType = (data) => {
  //   // return data.category_type;
  //   if (data.category_type == 7) {
  //     return "Knowledge";
  //   } else if (data.category_type == 8) {
  //     return "Comprehension";
  //   } else if (data.category_type == 9) {
  //     return "Application";
  //   } else if (data.category_type == 10) {
  //     return "Analysis";
  //   } else if (data.category_type == 11) {
  //     return "Evaluation";
  //   } else if (data.category_type == 12) {
  //     return "Synthesis";
  //   } else {
  //     return;
  //   }
  // };

  getAnswer = (options) => {
    let answer = "No Explanation Found!";
    const opt_index = ["A.", "B.", "C.", "D."];
    if (options.length > 1) {
      let filteredArray = options.filter((opt) => opt.endsWith("*"));
      let index = filteredArray.map((element) => options.indexOf(element));
      filteredArray.unshift(opt_index[index]);
      answer = filteredArray.join(" ");
      answer = answer.substring(0, answer.length - 1);
    } else {
      answer = options;
    }
    return answer;
  };

  getAccordion = (data) => {
    const {
      options,
      pro_tips: explanation,
      question_id: id,
    } = this.props.question;

    const answer = this.getAnswer(options);
    const is_descriptive = options.length === 1;

    return (
      <React.Fragment>
        <p className="mb-0 mt-3">
          <button
            class="btn-sm btn-primary"
            type="button"
            data-toggle="collapse"
            data-target={`#collapseExample_${id}`}
            aria-expanded="false"
            aria-controls={`collapseExample_${id}`}
            onClick={() =>
              this.setState({
                accordionText:
                  this.state.accordionText == "Hide Answer"
                    ? "View Answer"
                    : "Hide Answer",
              })
            }
          >
            {this.state.accordionText}
            {this.state.accordionText === "View Answer" ? (
              <span class="accordion-custom"> {">"} </span>
            ) : (
              <span class="rotate-accordion accordion-custom"> {">"} </span>
            )}
          </button>
        </p>
        <div class="collapse" id={`collapseExample_${id}`}>
          <div class="card-bootstrap card-body">
            <span className={classNames(styles["qna-ans"], "selected")}>
              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                {is_descriptive ? "Ans" : "The correct option is"}
              </span>{" "}
              - <span className={!is_descriptive ? "color-prepai-green": ""}>{answer}</span>
            </span>
            {_.isArray(data.image_data) && data.image_data[1] == "inside" && (
              <div className="">
                <Zoom>
                  <img
                    src={`data:image/png;base64, ${data.image_data[0]}`}
                    alt="refernce image"
                    style={{ width: "30%" }}
                  />
                </Zoom>
              </div>
            )}
            {!is_descriptive && explanation && explanation != "N/A" && (
              <ReadMore>{explanation}</ReadMore>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const data = this.props.question;
    const Ques = this.props.count;

    if (!data.question) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="each-question-wrapper-box-outer">
          <div className="each-question-wrapper-box">
            {this.state.editquestionpopup == true ? (
              <EditBloomTaxanomy
                data={this.props.question}
                showalert={this.handlealert}
                edit={this.editbutton}
                handleQuestionUpdate={this.props.handleQuestionUpdate}
                disable={this.state.disable}
                isDemo={this.props.isDemo}
                setModal={this.props.setModal}
                // addOnData={{ source: this.props.data.source }}
              ></EditBloomTaxanomy>
            ) : (
              <div>
                <div
                  className={classNames(
                    styles["qna-container"],
                    "each-question-wrapper-box--body"
                  )}
                >
                  <div>
                    {/* <Tooltip title="Coming Soon" id="global"></Tooltip> */}
                    <div className="row">
                      <div
                        className="col-12 d-flex justify-content-between"
                        style={{
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                        }}
                      >
                        <p
                          className={classNames(
                            styles["qna-q-title"],
                            "position-relative",
                            "mb-0"
                          )}
                        >
                          <span>Q{Ques}. </span>
                          {
                            _.isArray(data.question)
                              ? data.question.map((q, k) => {
                                  if (k == 0) {
                                    // return q.substring(7, q.length);
                                    return q;
                                  } else {
                                    return (
                                      <React.Fragment>
                                        <span className="ml-3 d-inline-block">
                                          {q}
                                        </span>
                                      </React.Fragment>
                                    );
                                  }
                                })
                              : data.question
                            // data.question.substring(7, data.question.length)
                          }
                        </p>
                        {/* <div role="button" onClick={(e) => this.editbutton(e)}>
                          <Edit />
                        </div> */}
                        <div
                          role="button"
                          onClick={(e) => this.editbutton(e)}
                          style={{ marginTop: "-10px" }}
                        >
                          <Tooltip title="Edit">
                            <IconButton>
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                        <span>
                          <b>{this.showCategoryType(data)}</b>
                        </span>
                      </div> */}
                    </div>
                    {_.isArray(data.image_data) &&
                      data.image_data[1] == "outside" && (
                        <p className="text-center mt-2">
                          <Zoom>
                            <img
                              src={`data:image/png;base64, ${data.image_data[0]}`}
                              alt="refernce image"
                              style={{ width: "30%" }}
                            />
                          </Zoom>
                        </p>
                      )}
                    {/* <div className="rate--question large-star">
                      <span>Rate Question</span>
                      <div className="d-inline-block line-height-0">
                        <Rating
                          className="ratyli"
                          start={0}
                          stop={5}
                          step={1}
                          onChange={this.handleRatingQuestion}
                          initialRating={this.state.ques_rating}
                          emptySymbol="rate rate-empty far fa-star fa-2x"
                          fullSymbol="rate rate-full fas fa-star fa-2x"
                        />
                      </div>
                    </div> */}
                    <div
                      className={classNames(
                        styles["qna-ans-container"],
                        "custom--ol--question"
                      )}
                    >
                      {/* <div className="custom--ol--question cusror-mover"> */}
                      {data.options.length > 1 && (
                        <ol
                          id={`options_${data.question_id}`}
                          type="A"
                          start="1"
                          className={classNames(styles["qna-ans"])}
                        >
                          {data.options.map((item, k) => (
                            <li
                              key={`option_${data.question_id}_${k}`}
                              className={classNames(
                                styles["qna-ans"],
                                `list-group-item-each`
                              )}
                              style={{ wordBreak: "break-word" }}
                            >
                              {item.endsWith("*")
                                ? item.substring(0, item.length - 1)
                                : item}
                            </li>
                          ))}

                          {/* <ReactSortable
                          list={data.options}
                          setList={(newState) =>
                            this.setState({
                              options: newState,
                            })
                          }
                        >
                          {data.options.map((item, k) => (
                            <li
                              key={`option_${data.question_id}_${k}`}
                              className={`list-group-item-each ${
                                item.endsWith("*") ? "selected" : ""
                              }`}
                            >
                              {item.endsWith("*")
                                ? item.substring(0, item.length - 1)
                                : item}
                            </li>
                          ))}
                        </ReactSortable> */}
                        </ol>
                      )}
                    </div>
                    <div
                      className="hidden-more-options custom--ol--question cusror-mover collapse"
                      id="moreoptionThree"
                    >
                      <ol
                        id={`moreoptions_${data.question_id}`}
                        type="A"
                        start="5"
                      >
                        <li className="list-group-item-each">
                          An object at rest stays at rest and an object in
                          motion stays in motion.
                        </li>
                        <li className="list-group-item-each">
                          Individuals define physics by what it was rather than
                          what it is and will be.
                        </li>
                        <li className="list-group-item-each">
                          What we mean by this is that things keep changing in
                          the world of physics with every discovery.
                        </li>
                        <li className="list-group-item-each">
                          As theories progress and discoveries are made, not
                          only the answer but the whole question.
                        </li>
                      </ol>
                    </div>
                  </div>
                  {this.getAccordion(data)}
                  {/* /* <div>
              <div className="inside-question-box">
                <div className="quetsion--edit">
                  <div className="quetsion--edit--markquee">Q.</div>
                  <input
                    type="text"
                    className="quedtion--text--fields"
                    value="What is Physics? How would you define it in the context of Physics?"
                  />
                </div>
                <div className="editable--questions">
                  <div className="quetsion--edit--markquee">
                    <label className="mordern-radiobox-label my-0">
                      <input type="radio" name="questions2" />
                      <span className="design"></span>
                    </label>
                  </div>
                  <input
                    type="text"
                    className="quedtion--text--fields"
                    value="An object at rest stays at rest and an object in motion stays in motion."
                  />
                </div>
                <div className="editable--questions">
                  <div className="quetsion--edit--markquee">
                    <label className="mordern-radiobox-label my-0">
                      <input type="radio" name="questions2" />
                      <span className="design"></span>
                    </label>
                  </div>
                  <input
                    type="text"
                    className="quedtion--text--fields"
                    value="The acceleration of an object is dependent upon two variables."
                  />
                </div>
                <div className="editable--questions">
                  <div className="quetsion--edit--markquee">
                    <label className="mordern-radiobox-label my-0">
                      <input type="radio" name="questions2" />
                      <span {this.state.editquestionpopup == true ? <Editeasymcq></Editeasymcq>: null} className="design"></span>
                    </label>
                  </div>
                  <input
                    type="text"
                    className="quedtion--text--fields"
                    value="The first law states that for every action (force) in nature there is an equal and opposite reaction."
                  />
                </div>
                <div className="editable--questions">
                  <div className="quetsion--edit--markquee">
                    <label className="mordern-radiobox-label my-0">
                      <input type="radio" name="questions2" />
                      <span className="design"></span>
                    </label>
                  </div>
                  <input
                    type="text"
                    className="quedtion--text--fields"
                    value="Force equals mass times acceleration. For every action, there is an equal and opposite reaction."
                  />
                </div>
              </div>
              <div className="editable-question-submitbox">
                <button
                  type="button"
                  role="button"
                  className="preview--paper--btn font--400 font--14 w-110"
                >
                  Save
                </button>
                <button
                  role="button"
                  type="putton"
                  className="preview--button font--400 font--14 w-110 ml-2"
                >
                  Cancel
                </button>
              </div>
                </div> */}
                </div>

                <div className="each-question-wrapper-box--footer">
                  <div className="row align-items-center justify-content-between no-gutters">
                    <span
                      style={{
                        fontSize: "small",
                      }}
                    >
                      <span style={{ fontWeight: "500" }}>Page:</span>{" "}
                      {data?.page_no ? data.page_no : "N/A"}
                    </span>
                    {/* <div className="col-12 col-175">
                      <div className="content--pill">
                        <button
                          className="content--pill--btn"
                          type="button"
                          role="button"
                          data-tip
                          data-for="global"
                          onClick={(e) => e.preventDefault()}
                        >
                          More Options
                        </button>
                      </div>
                    </div> */}
                    {/* <div className="col-auto">
                      <div className="content--pill">
                        <button
                          className="content--pill--btn"
                          type="button"
                          role="button"
                          name="edit-button"
                          //data-tip
                          //data-for="global"
                          onClick={(e) => this.editbutton(e)}
                          //onClick={(e) => e.preventDefault()}
                        >
                          Edit Question
                        </button>
                      </div>
                    </div> */}
                    {this.state.editquestionpopup == true ? (
                      <EditBloomTaxanomy></EditBloomTaxanomy>
                    ) : null}
                    {/* <div className="col-auto">
                      <div className="content--pill">
                        <div className="rate--question mb-0 p-10 large-star">
                          <span>Rate Options</span>
                          <div className="d-inline-block line-height-0">
                            <Rating
                              className="ratyli"
                              start={0}
                              stop={5}
                              step={1}
                              onChange={this.handleRatingOptions}
                              initialRating={this.state.opt_rating}
                              emptySymbol="rate rate-empty far fa-star fa-2x"
                              fullSymbol="rate rate-full fas fa-star fa-2x"
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-auto">
                      <div className="content--pill">
                        <div className="rate--question mb-0 p-10 d-flex align-items-center">
                          <span>Delete Question?</span>
                          <div className="d-inline-block font-0 ml-2">
                            <label
                              className="mordern-radiobox-label my-0"
                              // onClick={() => this.handleisRequiredClick(1)}
                            >
                              {/* <input
                                type="radio"
                                className="radio-class-2"
                                name={`isrequired_${data.question_id}`}
                                onChange={this.handleisRequiredChange}
                                value={1}
                                checked={
                                  this.state.questionRequired == 1
                                    ? true
                                    : false
                                }
                              />
                              <span className="design"></span>
                              <span className="text">Yes</span> */}
                              <Switch
                                onChange={this.handleisRequiredClick}
                                checked={this.state.checked}
                                onColor="#FF0000"
                              />
                            </label>
                            <label
                              className="mordern-radiobox-label ml-2 my-0"
                              // onClick={() => this.handleisRequiredClick(0)}
                            >
                              {/*  <input
                                id="popup-2"
                                className="radaio-pop radio-class-2"
                                type="radio"
                                name={`isrequired_${data.question_id}`}
                                onChange={this.handleisRequiredChange}
                                value={0}
                                checked={
                                  this.state.questionRequired == 0
                                    ? true
                                    : false
                                }
                              />
                              <span className="design"></span>
                              <span className="text">No</span>
                              {this.state.questionRequired == 0 && (
                                <React.Fragment>
                                  <Tooltip
                                    title="Edit Comment"
                                    id="editcomment"
                                  />
                                  <span
                                    className="what--wrong"
                                    data-tip
                                    data-for="editcomment"
                                  >
                                    <i
                                      className="fa fa-edit ml-2"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </React.Fragment>
                              )} */}
                              {/* {this.state.questionRequired == 0 &&
                                this.state.reasonBox == 0 &&
                                this.state.openBoxDefault && (
                                  <div className="custom--tooltip--nobox">
                                    <span></span>
                                    <textarea
                                      name="comment"
                                      id={`comment_${data.question_id}`}
                                      onChange={this.handleChangeComment}
                                      value={this.state.reasonComment}
                                      placeholder="Give reason to elaborate your answer..."
                                    ></textarea>
                                    <button
                                      type="button"
                                      role="button"
                                      className="preview--paper--btn comment-save-btn font--400 font--14"
                                      onClick={this.handleCommentSave}
                                    >
                                      Save
                                    </button>
                                    <button
                                      role="button"
                                      type="button"
                                      className="closebtn"
                                      onClick={(e) =>
                                        this.handleCloseReasonBox(e)
                                      }
                                    >
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                )} */}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>{this.state.alert}</div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(bloomsTexanomay);
