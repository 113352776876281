import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {
	btUpdateToken,
	fetchUser,
	inviteEnterpriseMembers,
	updateChecklistStatus,
} from "./../services";
import {
	showNotificationBar,
	closeNotificationBar,
	closeRequestInQueuePrompt,
} from "./../store/reducers/generalActions";
import NotificationBar from "./common/notificationBar";
import SweetAlert from "react-bootstrap-sweetalert";
import Salebar, {LifetimePlanTimer} from "./sales";
import MSG, {notifySuccess, notifyError} from "./../utils/Helper";
import {startLoader, stopLoader} from "./../store/reducers/generalActions";
import InviteMembers from "./inviteEnterpriseMember";
import _ from "lodash";
import CustomizedSteppers from "./common/Stepper";
import {authLogout} from "../store/reducers/auth";
import ProfileProgress from "./common/ProfileProgress";
import ProfileUpdate from "./common/ProfileUpdate";

import {Menu, Close, Launch} from "@mui/icons-material";
import styles from "../styles/headerResponsive.module.css";
import classNames from "classnames";

class Header extends Component {
	state = {
		refreshing: true,
		showAlert: false,
		showReferral: false,
		referralEmail: "",
		referralMessage: "",
		showBadge: true,
		showBar: false,
		showInvitePopUp: false,
		stepsCompleted: [],
		stepsCount: 0,
		isClosed: true,
		menuOpen: false,
		windowWidth: 1080,
		position: "normal",
		lifetime_timer: null,
	};

	constructor(props) {
		super(props);

		this.wrapperRef = React.createRef();
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		this.handleResize();
		window.addEventListener("resize", this.handleResize);
		this.setState({
			refreshing: false,
			showReferral: false,
		});
		document.addEventListener("mousedown", this.handleClickOutside);
		// this.timer = setInterval(() => {
		//   // Set the date we're counting down to(Sale end date)
		//   var countDownDate = new Date("Dec 12, 2022 12:00:00").getTime();
		//   // Convert local time to IST
		//   countDownDate = countDownDate.toLocaleString("en-US", {
		//     timeZone: "Asia/Kolkata",
		//   });

		//   // get today's date
		//   var today = new Date().getTime();
		//   today = today.toLocaleString("en-US", {
		//     timeZone: "Asia/Kolkata",
		//   });

		//   if (today >= countDownDate) this.setState({ showBar: true });
		// }, 1000);
	}
	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
		window.removeEventListener("resize", this.handleResize);
	}

	updateStatus = async stepsCount => {
		this.props.dispatch(startLoader());
		this.setState({isClosed: true});
		try {
			let req = {status: 2};
			if (stepsCount === 3) {
				req = {status: 1};
			}
			const {data: res} = await updateChecklistStatus(req);
			if (res.success === true) {
				await this.props.dispatch(fetchUser());
				if (stepsCount === 3)
					this.props.history.replace("/coupons-and-offers");
			}
		} catch (err) {
			console.log(err);
			if (err.response && err.response.status === 422) {
				const {data} = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			} else if (err.response && err.response.status === 401) {
				const {data} = err.response;
				notifyError(data.msg);
				this.props.dispatch(authLogout());
			} else {
				notifyError(MSG.commonApiError);
			}
		} finally {
			this.props.dispatch(stopLoader());
		}
	};
	/**
	 * Alert if clicked on outside of element
	 */
	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
			this.props.dispatch(closeNotificationBar());
			// this.props.dispatch(stopLoader());
		}
	}

	handleLogout = () => {
		localStorage.removeItem("access_token");
		sessionStorage.clear();
		window.location.reload();
	};

	handleNotification = () => {
		if (this.props.notificationBarShow) {
			this.props.dispatch(closeNotificationBar());
		} else {
			this.props.dispatch(showNotificationBar());
			this.setState({showBadge: false});
		}
	};

	showPlanInfoHeader = info => {
		const is_free_trial = info.plan_details.free_trial;
		const remaining_question_paper = info.remaining_question_papers;
		const generatedQustions = info.quiz_generated;
		const isLifetime = info.plan_details.plan_type === "Lifetime_All";
		const showLifetimeFomo = this.props.showLifetimeFomo;
		let header_message = (
			<React.Fragment>
				<b className='nav-link' style={{fontWeight: 600}}>
					{isLifetime ? `Quiz Generated: ` : `Remaining quizzes: `}
					<span
						style={{
							color: !isLifetime ? "red" : "green",
						}}
					>
						&nbsp;
						{isLifetime ? generatedQustions : remaining_question_paper}
					</span>
				</b>{" "}
			</React.Fragment>
		);

		if (showLifetimeFomo) {
			header_message = (
				<React.Fragment>
					<li className='nav-item fl-center'>
						<b style={{color: "#0808a9", display: "flex"}}>
							This Page Expires In:{" "}
							<div className='ml-1' style={{color: "red"}}>
								{this.state.lifetime_timer && <LifetimePlanTimer />}
							</div>
						</b>
					</li>
				</React.Fragment>
			);
		} else if (is_free_trial) {
			// Create a Date object representing the current date
			const currentDate = new Date();
			const plan_expirey_date = new Date(info.plan_expired_at);
			// Calculate the difference in milliseconds
			const differenceInMilliseconds = plan_expirey_date - currentDate;

			// Convert milliseconds to days
			const differenceInDays =
				Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)) + 1;

			if (differenceInDays === 1) {
				header_message = (
					<React.Fragment>
						<li className='nav-item fl-center'>
							Your 15-day free trial <b className='ml-1'>ends Today.</b>
							<div className='notify-tool-tip nav-item'>
								<i
									style={{
										marginTop: "3px",
										marginLeft: "10px",
										fontSize: "1rem",
									}}
									className='fas fa-info-circle instructions'
									onClick={() => this.setState({showAlert: true})}
								></i>
								<span
									className={classNames(
										styles["hoverText-remaining"],
										"hoverText"
									)}
									>
									Remaining Quizzes: {remaining_question_paper}
									</span>
							</div>
						</li>
					</React.Fragment>
				);
			} else if (differenceInDays > 1) {
				header_message = (
					<React.Fragment>
						<li
							className='nav-item fl-center'
						>
							<b className="nav-item">
								Your 15-day free trial ends in{" "}
							</b>
							<span
								className='ml-1'
								style={{color: "red", }}
							>
								{differenceInDays} days
							</span>
							<div className='notify-tool-tip nav-item'>
								<i
									style={{
										marginTop: "3px",
										marginLeft: "10px",
										fontSize: "1rem",
									}}
									className='fas fa-info-circle instructions'
								></i>
								<span
									className={classNames(
										styles["hoverText-remaining"],
										"hoverText"
									)}
								>
									Remaining Quizzes: {info.remaining_question_papers}
								</span>
							</div>
						</li>
					</React.Fragment>
				);
			} else if (differenceInDays < 1) {
				header_message = (
					<React.Fragment>
						<span className='nav-link' style={{color: "red"}}>
							Trial period{" "}
							<b>
								&nbsp;expired {Math.abs(differenceInDays) + 1}{" "}
								{Math.abs(differenceInDays) === 0 ? "day" : "days"} ago.
							</b>
						</span>
					</React.Fragment>
				);
			}
		}

		return <div>{header_message}</div>;
	};

	showHeaderTextPlan = () => {
		if (this.props.user.plan_details && this.props.user.role != 2) {
			var planButtonText = "Upgrade Plan";

			if (
				this.props.user.plan_details.plan_name == "Yearly" ||
				this.props.user.role === 1 ||
				this.props.user.plan_details.plan_type == "Lifetime_All"
			) {
				var planButtonText = "View Plan";
			}
			return (
				<React.Fragment>
					<React.Fragment>
						<li
							className={classNames(
								styles["hide"],
								"nav-item fl-center"
							)}
						>
							<Link
								style={
									this.props.user.plan_details.plan_type ==
									"Lifetime_All"
										? {opacity: "0.7", width: "max-content"}
										: {opacity: "1", width: "max-content"}
								}
								className='btn upgrade-plan-btn'
								to='/plans'
							>
								{planButtonText}
							</Link>
						</li>

						{this.props.user?.plan_details?.is_new_pricing === 1 &&
							this.props.user?.plan_details?.plan_name !== "Free" &&
							this.props.user?.plan_details?.plan_name !== "Trial" &&
							this.props.user?.plan_details?.plan_type !==
								"Lifetime_All" && (
								<li
									className={classNames(
										styles["hide"],
										"nav-item fl-center"
									)}
								>
									<Link
										className='btn upgrade-plan-btn'
										to='/addon-plans'
										style={{width: "max-content"}}
									>
										Add-On Plans
									</Link>
								</li>
							)}
					</React.Fragment>
				</React.Fragment>
			);
		}
	};

	// showHeaderApiAccess = () => {
	//   if (this.props.user.plan_details && this.props.user.role === 0) {
	//     var planButtonText = "Get API Access";
	//     return (
	//       <React.Fragment>
	//         <React.Fragment>
	//           <li className="nav-item fl-center">
	//             <a
	//               href="https://rapidapi.com/DataToBiz/api/prepai-generate-questions"
	//               target="_blank"
	//               rel="noreferrer"
	//               className="btn btn-outline-success"
	//             >
	//               {planButtonText}
	//             </a>
	//           </li>
	//         </React.Fragment>
	//       </React.Fragment>
	//     );
	//   }
	// };

	showCheckListSteps = () => {
		let {checklist_status, checklist_steps} = this.props.user;
		checklist_status =
			window.location.href.includes("/verification-required") ||
			window.location.href.includes("/coupons-and-offers")
				? 1
				: checklist_status; //disbales checklist for verification page
		return (
			<React.Fragment>
				{checklist_status === 0 && (
					<div className='customised-stepper'>
						<CustomizedSteppers steps={checklist_steps} />
						{checklist_steps.length >= 3 && (
							<span
								onClick={() =>
									this.updateStatus(checklist_steps.length)
								}
								style={{
									backgroundColor: "#49c0a0",
									color: "white",
									width: "100%",
								}}
								className='d-block text-center btn text-white'
							>
								Take me to offers and coupons section {">>"}
							</span>
						)}
					</div>
				)}
			</React.Fragment>
		);
	};

	profileProgressHandle = () => {
		if (
			this.props.user &&
			(!this.props.user.contact_number ||
				!this.props.user.profession ||
				!this.props.user.source_from)
		) {
			if (this.props.user?.checklist_steps?.length > 0) {
				this.setState({
					profileCompletionPopup: !this.state.profileCompletionPopup,
				});
			}
		}
	};

	getProfileProgress = profileData => {
		let progress = 40;
		if (this.props.user) {
			const {contact_number, profession, source_from} = profileData;
			if (contact_number && profession && source_from) {
				progress = 100;
			} else if (
				(contact_number && profession) ||
				(contact_number && source_from) ||
				(profession && source_from)
			) {
				progress = 80;
			} else if (contact_number || profession || source_from) {
				progress = 60;
			}
		}
		return progress;
	};

	showHeaderTextFreePlan = () => {
		if (this.props.user.plan_details) {
			return (
				<React.Fragment>
					{this.props.user.plan_details.plan_name == "Free" ? (
						<React.Fragment>
							<li
								className='nav-item'
								style={{position: "absolute", top: 0, right: "10px"}}
							>
								<span className='btn btn-main btn-sm disabled'>
									Free Plan
								</span>
							</li>
						</React.Fragment>
					) : (
						<li
							className='nav-item'
							style={{position: "absolute", top: 0, right: "10px"}}
						>
							<span
								className={`btn btn-${this.props.user.plan_details.plan_name} btn-sm`}
							>
								{this.props.user.plan_details.plan_name} Plan
							</span>
						</li>
					)}
				</React.Fragment>
			);
		}
	};

	showUserPlan = () => {
		if (
			this.props.user.plan_details &&
			!this.props.user.plan_details.is_plan_expired
		) {
			return (
				<React.Fragment>
					{this.props.user.plan_details.plan_name == "Free" ? (
						<span className='basic'>Basic</span>
					) : (
						<span
							className={
								this.props.user.role === 2
									? "enterprise-member"
									: this.props.user.role === 1
									? "enterprise-admin"
									: "premium"
							}
							style={
								this.props.user.plan_details.free_trial
									? {
											marginLeft: "-84px",
											fontSize: "x-small",
									  }
									: {}
							}
						>
							{this.props.user.role === 2
								? "Enterprise Member"
								: this.props.user.role === 1
								? "Enterprise Admin"
								: this.props.user.plan_details.free_trial
								? "Premium Trial"
								: this.props.user.plan_details.plan_name === "Lifetime"
								? "Lifetime"
								: "Premium"}
						</span>
					)}
				</React.Fragment>
			);
		}
	};

	redirectToBloom = async () => {
		this.props.dispatch(startLoader());
		try {
			const {data: res} = await btUpdateToken();
			if (res.success == true) {
				window.open(
					`${process.env.REACT_APP_HOTSPREPAI_APP_URL}/sso?token=${res.response.token}`
				);
			} else {
				notifyError("Something went wrong");
			}
		} catch (err) {
			console.log(err);
			notifyError(MSG.commonApiError);
		}
		this.props.dispatch(stopLoader());
	};

	inviteEnterpriseMembers = async Emails => {
		this.props.dispatch(startLoader());
		try {
			const {data: response} = await inviteEnterpriseMembers({
				inviteEmails: Emails,
			});

			if (response.success == true) {
				// this.props.dispatch(fetchUser());
				notifySuccess(response.message);

				this.setState({
					showInvitePopUp: false,
				});
			} else {
				notifyError(response.message);
			}
		} catch (err) {
			console.log(err);
			notifyError(MSG.commonApiError);
		}
		this.props.dispatch(stopLoader());
	};

	handleInviteCallBack = data => {
		if (data.hidePopUp) {
			this.setState({
				showInvitePopUp: !data.hidePopUp,
			});
			return;
		}

		// let inviteEmails = data.inviteMembers;
		// if (!_.isEmpty(inviteEmails)) {
		//   this.inviteEnterpriseMembers(inviteEmails);
		//   return;
		// }
	};

	// Convert local time to specific timezone
	convertDateTime = date => {
		return new Date(
			new Date(date).toLocaleString("en-US", {
				timeZone: "Asia/Kolkata",
			})
		);
	};

	componentDidUpdate = async prevProps => {
		this.showCheckListSteps();
		const lifetime_timer = Number(sessionStorage.getItem("lifetime_timer"));
		const lifetime_timer_till = Number(
			sessionStorage.getItem("lifetime_timer_till")
		);

		// get today's date
		var today = new Date().getTime();
		today = this.convertDateTime(today);

		if (
			lifetime_timer &&
			lifetime_timer !== NaN &&
			lifetime_timer_till &&
			lifetime_timer_till !== NaN
		) {
			let countDownDate = this.convertDateTime(lifetime_timer_till);
			if (countDownDate < today) {
				if (this.state.lifetime_timer)
					this.setState({lifetime_timer: false});
			} else {
				if (!this.state.lifetime_timer)
					this.setState({
						lifetime_timer,
					});
			}
		}
		if (prevProps.showLifetimeFomo !== this.props.showLifetimeFomo) {
			if (lifetime_timer_till && lifetime_timer_till !== NaN) {
				let countDownDate = this.convertDateTime(lifetime_timer_till);

				if (countDownDate < today) {
					if (this.state.lifetime_timer)
						this.setState({lifetime_timer: false});
				} else {
					if (!this.state.lifetime_timer)
						this.setState({
							lifetime_timer,
						});
				}
			}
		}
	};

	handleResize = () => {
		this.setState({windowWidth: window.innerWidth});
	};

	mobileShowHeaderTextPlan = () => {
		if (this.props.user.plan_details && this.props.user.role != 2) {
			if (
				this.props.user.plan_details.plan_name == "Yearly" ||
				this.props.user.role === 1 ||
				this.props.user.plan_details.plan_type == "Lifetime_All"
			) {
				return "View Plan";
			}
			return "Upgrade Plan";
		}
	};

	render() {
		let pathname = this.props.location.pathname;
		const paths = ["/verification-required", "/referral-code"];
		return (
			<React.Fragment>
				{this.state.showBar && (
					<div className='row col-md-12 countdwn-home'>
						<div
							className='col-md-8'
							style={{justifyContent: "end", display: "flex"}}
						>
							<p className='countdown_msg_bt'>
								Bloom’s Taxonomy update is live!
							</p>
							<span
								className='learnmorebtn-bt'
								onClick={this.redirectToBloom}
								style={{cursor: "pointer"}}
							>
								{" "}
								Try it now!{" "}
							</span>
						</div>
					</div>
				)}
				<div
					style={paths.includes(pathname) ? {display: "none"} : {gap: "1"}}
					className='header-parent'
				>
					<Salebar></Salebar>
					<div style={{display: "none"}}>
						{this.state.lifetime_timer && <LifetimePlanTimer />}
					</div>

					<div
						className='custom-navbar'
						style={{
							position: this.state.menuOpen ? "fixed" : "relative",
							minWidth: "100%",
						}}
					>
						<nav className='navbar navbar-expand-md'>
							<div className='container' style={{minWidth: "100%"}}>
								<div className='style-logo'>
									<Link
										className='navbar-brand'
										to='/generate-questions'
										style={{marginRight: "0px"}}
									>
										<img
											src={`${process.env.REACT_APP_PROJECT_URL}/img/prepAI-new-logo.svg`}
											alt='prepAi-logo'
											className='prepai-logo-nav'
										/>
									</Link>
									{this.showUserPlan()}
								</div>
								<div className={classNames(styles["show-mobile"])}>
									<div className='notify-tool-tip' id='notify-bell'>
										<i
											style={{marginTop: "5px"}}
											className='far fa-bell notification'
											onClick={this.handleNotification}
										></i>
									</div>
									{this.props.user.new_notify_count > 0 && (
										<span
											style={{
												marginTop: "18px",
												marginLeft: "14px",
												marginRight: "14px",
												fontSize: "10px",
											}}
											className={classNames(
												styles["notify-count"],
												"position-absolute rounded-pill badge style-badge text-white"
											)}
										>
											{this.props.user.new_notify_count}
										</span>
									)}
									<button
										className='navbar-toggler'
										type='button'
										data-toggle='collapse'
										data-target='#navbarSupportedContent'
										aria-controls='navbarSupportedContent'
										aria-expanded='false'
										aria-label='Toggle navigation'
										onClick={() => {
											// console.log(this.state.menuOpen);
											let pos = "normal";
											if (!this.state.menuOpen) {
												pos = "fixed";
											}
											this.setState({
												menuOpen: !this.state.menuOpen,
												position: pos,
											});
										}}
									>
										<span className='navbar-toggler-icon'>
											{!this.state.menuOpen && (
												<Menu style={{pointerEvents: "none"}} />
											)}
											{this.state.menuOpen && (
												<Close style={{pointerEvents: "none"}} />
											)}
										</span>
									</button>
								</div>

								<div
									className='collapse navbar-collapse custom-navlinks'
									id='navbarSupportedContent'
								>
									<ul
										className={`navbar-nav ml-auto ${
											this.state.lifetime_timer
												? "middle-element"
												: ""
										}`}
									>
										{this.props.user.role === 1 && (
											<>
												<li
													className={classNames(
														styles["hide"],
														styles["nav-item"],
														styles["drop-down"],
														"nav-item dropdown fl-center"
													)}
												>
													<a
														className='nav-link dropdown-toggle'
														href='#'
														id='navbarDropdown'
														role='button'
														data-toggle='dropdown'
														aria-haspopup='true'
														aria-expanded='false'
													>
														<span
															style={{padding: "0 3px 0 10px"}}
														>
															Enterprise
														</span>
														<span
															className='ml-1 arr-stroke'
															style={{width: "0.5rem"}}
														>
															<svg
																width='15'
																height='9'
																viewBox='0 0 15 9'
																fill='none'
															>
																<path
																	d='M14 1L7.5 8L1 1'
																	stroke='#526892'
																	strokeLinecap='square'
																/>
															</svg>
														</span>
													</a>
													<div
														className='dropdown-menu navdrop--menu'
														aria-labelledby='navbarDropdown'
													>
														<Link
															className='dropdown-item'
															to='/dashboard'
														>
															View Dashboard
														</Link>
														<Link
															className='dropdown-item'
															to='/user-management'
														>
															Manage Users
														</Link>
													</div>
												</li>
											</>
										)}

										{this.state.windowWidth <= 767 && (
											<div
												className={classNames(styles["divider"])}
											></div>
										)}
										{!_.isEmpty(this.props.user) &&
											this.props.user.plan_details.is_new_pricing ===
												1 && (
												<>
													<li
														className={classNames(
															styles["nav-item"],
															"nav-item fl-center"
														)}
													>
														<span
															className='nav-link'
															style={{fontSize: "14px"}}
														>
															{this.showPlanInfoHeader(
																this.props.user
															)}
														</span>
													</li>
													{this.state.windowWidth <= 767 && (
														<div
															className={classNames(
																styles["divider"]
															)}
														></div>
													)}
												</>
											)}
										{!this.state.lifetime_timer && (
											<li
												className={classNames(
													styles["show-mobile"],
													styles["nav-item"],
													"nav-item fl-center"
												)}
											>
												<Link className='nav-link' to='/plans'>
													{this.mobileShowHeaderTextPlan()}
												</Link>
											</li>
										)}
										{!this.state.lifetime_timer &&
											this.props.user?.plan_details
												?.is_new_pricing === 1 &&
											this.props.user?.plan_details?.plan_name !==
												"Free" &&
											this.props.user?.plan_details?.plan_name !==
												"Trial" &&
											this.props.user?.plan_details?.plan_name !==
												"Expired" &&
											this.props.user?.plan_details?.plan_type !==
												"Lifetime_All" && (
												<>
													{this.state.windowWidth <= 767 && (
														<div
															className={classNames(
																styles["divider"]
															)}
														></div>
													)}
													<li
														className={classNames(
															styles["show-mobile"],
															styles["nav-item"],
															"nav-item fl-center"
														)}
													>
														<Link
															className='nav-link'
															to='/addon-plans'
														>
															Add-On Plans
														</Link>
													</li>
												</>
											)}
										{this.state.windowWidth <= 767 && (
											<div
												className={classNames(styles["divider"])}
											></div>
										)}
										{!this.state.lifetime_timer &&
											this.showHeaderTextPlan()}
										{!this.state.lifetime_timer &&
											this.props.user.email && (
												<li
													className={classNames(
														styles["nav-item"],
														"nav-item fl-center"
													)}
												>
													{![
														"/question-paper",
														"/my-assessments",
														"/my-tests",
													].includes(
														this.props.location.pathname
													) ? (
														<Link
															className='nav-link'
															to='/question-paper'
														>
															My Question Papers
														</Link>
													) : (
														<Link
															className='nav-link'
															to='/generate-questions'
														>
															Generate Question Papers
														</Link>
													)}
												</li>
											)}
										{this.state.windowWidth <= 767 && (
											<div
												className={classNames(styles["divider"])}
											></div>
										)}
										{!this.state.lifetime_timer && (
											<li
												className={classNames(
													styles["hide"],
													"nav-item fl-center"
												)}
											>
												<div className='notify-tool-tip'>
													<i
														style={{marginTop: "6px"}}
														className='fas fa-info-circle instructions'
														onClick={() =>
															this.setState({showAlert: true})
														}
													></i>
													<span className='hoverText'>
														Instructions
													</span>
												</div>
											</li>
										)}

										{!this.state.lifetime_timer && (
											<li
												className={classNames(
													styles["hide"],
													"nav-item fl-center"
												)}
												style={{position: "relative"}}
											>
												<div
													className='notify-tool-tip'
													id='notify-bell'
												>
													<i
														style={{marginTop: "5px"}}
														className='far fa-bell notification'
														onClick={this.handleNotification}
													></i>
												{this.props.user.new_notify_count > 0 && (
													<span
														style={{
															transform: "translate(-17px, 9px)",
															fontSize: "10px",
														}}
														className='position-absolute rounded-pill badge style-badge text-white'
													>
														{this.props.user.new_notify_count}
													</span>
												)}
												</div>
											</li>
										)}

										{!this.state.lifetime_timer && (
											<li
												className={classNames(
													styles["nav-item"],
													styles["drop-down"],
													"nav-item ml-1 dropdown fl-center"
												)}
												style={{
													marginLeft: "1rem !important",
													minWidth: "90px !important",
												}}
											>
												<a
													className={classNames(
														styles["profile-row"],
														"nav-link dropdown-toggle"
													)}
													href='/#'
													id='navbarDropdown'
													role='button'
													data-toggle='dropdown'
													aria-haspopup='true'
													aria-expanded='false'
													style={{paddingLeft: "20px !important"}}
												>
													<div style={{paddingRight: "10px"}}>
														<ProfileProgress
															progress={this.getProfileProgress(
																this.props.user
															)}
														/>
													</div>
													{this.props.user.name}
													<span className='ml-1 arr-stroke'>
														<svg
															width='15'
															height='9'
															viewBox='0 0 15 9'
															fill='none'
														>
															<path
																d='M14 1L7.5 8L1 1'
																stroke='#526892'
																strokeLinecap='square'
															/>
														</svg>
													</span>
												</a>

												{/* dropdown */}
												<div
													//    style={{position: "absolute", left: "0px"}}
													className={classNames(
														styles["header-dropdown-menu"],
														"dropdown-menu navdrop--menu"
													)}
													aria-labelledby='navbarDropdown'
												>
													{this.props.user.role !== 2 &&
														this.props.user.role !==
															undefined && (
															<Link
																className='dropdown-item'
																to='/my-account'
															>
																My Account
															</Link>
														)}
													<Link
														className='dropdown-item'
														to='/edit-profile'
													>
														Edit Profile
													</Link>
													<Link
														className='dropdown-item'
														to='/change-password'
													>
														Change Password
													</Link>
													{(this.props.user.role === 0 ||
														this.props.user.role === 2) &&
														this.props.user.plan_details
															.is_new_pricing === 1 && (
															<Link
																className='dropdown-item'
																to='/mydashboard'
															>
																View Dashboard
															</Link>
														)}
													<Link
														className='dropdown-item'
														to='/api-docs'
													>
														API Documentation
													</Link>
													{this.props.user.role !== 2 &&
														this.props.user.role !==
															undefined && (
															<Link
																className={classNames(
																	"dropdown-item"
																)}
																to='/coupons-and-offers'
															>
																Coupons & Offers
															</Link>
														)}
													<Link
														className='dropdown-item'
														to='https://affiliate.prepai.io/'
														onClick={() => {
															// Adding window here beacuase Link and to is not working on Click.
															window.open(
																"https://affiliate.prepai.io/",
																"_blank"
															);
														}}
													>
														Become our Affiliate <Launch />
													</Link>
													<Link
														className='dropdown-item'
														to='#'
														onClick={this.handleLogout}
													>
														Log out
													</Link>
												</div>
											</li>
										)}
									</ul>
								</div>
							</div>
						</nav>
						{this.props.user &&
							this.props.history.location.pathname !==
								"/verification-required" &&
							this.props.history.location.pathname !== "/plans" &&
							this.showCheckListSteps()}
					</div>
					<div ref={this.wrapperRef}>
						{this.props.notificationBarShow && <NotificationBar />}
					</div>
				</div>
				<SweetAlert
					customClass='sweet-alert'
					show={this.state.showAlert}
					showConfirm
					title={
						<div className='swal-title'>
							<h2>Please Read Carefully</h2>
							<hr />
							<ol>
								<li className='instruction-points'>
									<div style={{marginBottom: "-1rem"}}>
										<b>PrepAI Standard Mode</b> - Works best with
										Subjective type contents and generates multiple
										varieties of questions.
									</div>
									<br />
									<div style={{marginBottom: "-1rem"}}>
										<b>PrepAI HOTS Mode</b> - Works with a wider
										variety of contents and generates Higher Order
										Thinking questions.
									</div>
									<br />
									<div>
										<b>PrepAI Specialist Mode</b> - Build specifically
										for generating numerical questions for competitive
										exams.
									</div>
								</li>
								<li className='instruction-points'>
									PrepAI generates quality questions on quality
									content. It may not produce accurate results in cases
									where content is not structured, or contains tabular
									data, diagrams, etc.
								</li>
								<li className='instruction-points'>
									PrepAI is available for content in English Language.
								</li>
							</ol>
						</div>
					}
					confirmBtnText='OK'
					confirmBtnStyle={{fontSize: "16px"}}
					confirmBtnBsStyle='success'
					onEscapeKey={() => this.setState({showAlert: false})}
					onOutsideClick={() => this.setState({showAlert: false})}
					onConfirm={() => this.setState({showAlert: false})}
					onCancel={() => this.setState({showAlert: false})}
				/>
				<SweetAlert
					customClass='sweet-alert'
					show={this.props.requestInQueuePromptShow}
					title={
						<div className=''>
							<h4>Requests in Progress, Please Wait!</h4>
							<hr />
						</div>
					}
					confirmBtnText='I understand'
					confirmBtnStyle={{fontSize: "16px"}}
					confirmBtnBsStyle='success'
					onConfirm={() => {
						this.props.dispatch(closeRequestInQueuePrompt());
					}}
					onEscapeKey={() => {}}
					onOutsideClick={() => {}}
					onCancel={() => {}}
				>
					<div style={{marginBottom: "-1rem"}}>
						You have more than 3 requests in queue. Please wait until they
						are processed before generating new questions. Thank you!
					</div>
					<br />
				</SweetAlert>
				{this.props.notificationBarShow && <NotificationBar />}

				{this.state.showInvitePopUp && (
					<InviteMembers callBack={this.handleInviteCallBack} />
				)}
				{this.state.profileCompletionPopup &&
					this.props.history.location.pathname !==
						"/verification-required" && <ProfileUpdate />}
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		notificationBarShow: state.generalActions.notificationBarShow,
		requestInQueuePromptShow: state.generalActions.requestInQueuePromptShow,
		showLifetimeFomo: state.generalActions.showLifetimeFomo,
		user: state.auth.user,
	};
};
export default withRouter(connect(mapStateToProps)(Header));
