import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import produce from "immer";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { trackEvent } from "../utils/mixpanel";
import PhoneInput from "react-phone-input-2";
import { saveAs } from "file-saver";
import moment from "moment";

import {
  editQuestionPaper,
  updateInstructionRead,
  fetchUser,
  QuestionPaperRating,
  addPagination,
  updateUserDetails,
  GQSubjectExpertise,
  purchaseAddOns,
  purchaseFreePlan,
  getAddOnsList,
  seDownloadQuestionPaper,
  validationForDownloadQuePaper,
  createTest,
} from "../services";
import MSG, {
  notifySuccess,
  notifyError,
  profession,
  findUsList,
  notifyWarning,
} from "../utils/Helper";
import {
  closeReferral,
  setInitialQues,
  showReferral,
  startLoader,
  stopLoader,
  setSelectedPlan,
  showRequestInQueuePrompt,
} from "../store/reducers/generalActions";
import { authLogout } from "../store/reducers/auth";
import Tooltip from "./common/tooltip";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import "react-dropzone-uploader/dist/styles.css";
import AddDescriptive from "./addquestion/adddescriptive";
import { isMobile } from "react-device-detect";
import ReferPopup from "./common/ReferPopup";
import { TextField } from "@mui/material";
import ReactSelect, { components } from "react-select";
import {
  APTITUDE_TOPIC,
  CHEMISTRY_FORMULA,
  CHEMISTRY_TOPIC,
  MATHS_FORMULA,
  MATHS_TOPIC,
  PHYSICS_FORMULA,
  PHYSICS_TOPIC,
} from "./common/SpecialistData";
import ToggleForGQ from "./common/ToggleForGQ";
import SubjectExpertise from "./questionTypes/SubjectExpertise";
import {
  Elements,
  ElementsConsumer,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./common/AddOnsCheckoutForm";
import QuestionPaperTopUp from "./common/QuestionPaperTopUp";
import textData from "./common/textData";
import QuestionPaperPreviewExpertise from "./question-paper-preview-expertise";
import DonwloadQuestionPaper from "./common/DonwloadQuestionPaper";
import TakeAssessment from "./common/TakeAssessment";
import QuestionPaperSetsExpertise from "./common/questionPaperSetsExpertise";

import styles from "../styles/demoResponsive.module.css";
import classNames from "classnames";
import GenerateQuestionSpecialist from "./common/GenerateQuestionSpecialist";
// import { ExpandLess, ExpandMore } from "@mui/icons-material";

const InjectedCheckoutForm = (props, data) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm
        stripe={stripe}
        elements={elements}
        {...props}
        data={data}
      />
    )}
  </ElementsConsumer>
);

class SubjectExpertiseQuestions extends Component {
  state = {
    subject: [
      { value: "Mathematics", label: "Mathematics" },
      { value: "Physics", label: "Physics" },
      { value: "Chemistry", label: "Chemistry" },
      { value: "Aptitude", label: "Aptitude" },
    ],
    typeOfQuestion: [
      { value: "Formula Based", label: "Formula Based" },
      { value: "Topic Based", label: "Topic Based" },
    ],
    topic: [{ value: "", label: "" }],
    buttonType: "Easy",
    key_pcma: 1,
    level_pcma: "easy level",
    questionPaperGenerated: false,
    topic_formula: MATHS_TOPIC,
    topic_formula_name: MATHS_TOPIC[0],
    subject_name: { value: "Mathematics", label: "Mathematics" },
    typeOfQuestion_name: { value: "Topic Based", label: "Topic Based" },
    disableTopicFormula: false,
    titleofQuestion: this.props.defaultContent?.title,
    titleofQuestion_emsg: "",
    contentForQuestions: this.props.defaultContent?.content,
    wikiSearchInput: "",
    refreshing: false,
    typing: false,
    typingTimeout: 0,
    selectedWikiTopic: null,
    tmpselectedWikiTopic: "",
    showAlert: false,
    wikiContent: "",
    searchTopic: "",
    iseditpaper: true,
    currentActiveTab: 2,
    questions: [],
    ques_count: {
      easyCount: 0,
      mediumCount: 0,
      hardCount: 0,
      descriptiveCount: 0,
      trueFalseCount: 0,
      fillUpsCount: 0,
    },
    addquestionmodal: false,
    questiontypetab: "knowledge",
    defaultActiveTab: "custom-tab",
    addeasymcq: false,
    addmediummcq: false,
    addhardmcq: false,
    addtruefalse: false,
    adddescriptive: false,
    addfillups: false,
    isUploaded: false,
    videoFile: null,
    pdfDocFile: null,
    feedbackAlert: false,
    showRatingAlert: false,
    callRatingAPI: true,
    questionPaperId: null,
    questionPaperRating: null,
    timeLeft: {},
    highlightBadEmoji: null,
    highlightNeutralEmoji: null,
    highlightGoodEmoji: null,
    showPremiumPrompt: false,
    premiumPromptText: "",
    isShowPremiumPrompt: false,
    totalPages: 0,
    pageNo: 1,
    requiredQuestionCount: 11110,
    lessQuestionsGenerated: false,
    moreQuestionsGenerated: false,
    showPopup: false,
    firstEventScroll: false,
    activity_count: 0,
    isQuestionGenerated: false,
    source: "Direct",
    scrollAfterGenerate: false,
    leavePageEvent: false,
    showUpdateProfilePopUp: false,
    source_from: "",
    source_from_other: "",
    profession: "",
    profession_other: "",
    data: {
      contact_number: "",
      country_code: "in",
      dial_code: "",
      formatted_phone_number: "",
    },
    showLoader: false,
    showFeedbackForm: true,
    ratingComment: "",
    showWordLimit: false,
    isCustomPages: false,
    ques_count: null,
    totalQuestionsFormed: 0,
    stripePromise: null,
    stripeClientSecret: "",
    showStripeForm: false,
    stripeKey: process.env.REACT_APP_STRIPE_KEY,
    showAddOn: false,
    addOnText: "",
    addOnCTAText: "",
    freeAddOnSubText: "",
    addOnPlans: [],
    activatePlan: [],
    showDownloadPopUp: false,
    showTopUp: false,
    showPreview: false,
    totalQuestionsFormed: 0,
    showDurationAlert: false,
    timeSeconds: "00:00:00",
    timerSeconds: 0,
    showSetsPopUpExpertise: false,
  };

  calculateTimeLeft = () => {
    // Set the date we're counting down to
    const countDownDate = new Date("Oct 31, 2022 23:59:59").getTime();

    // get today's date
    const today = new Date().getTime();
    // get the difference
    let diff = countDownDate - today;

    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: undefined };

    if (diff > 0) {
      timeLeft = {
        // math
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((diff % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //this.handleGenerateQuestion();
  };

  isFutureDate = (idate) => {
    var today = new Date().getTime(),
      idate = idate.split("/");

    idate = new Date(idate[2], idate[1] - 1, idate[0]).getTime();
    return today - idate < 0 ? true : false;
  };

  async componentDidMount() {
    this.props.dispatch(getAddOnsList());

    if (this.props.location.state != undefined) {
      const { state } = this.props.location;
      if (state.editpaper) {
        this.props.dispatch(startLoader());
        const req = {
          content_id: state.editpaperid,
          is_sub_expertise: true,
        };
        this.setState({
          iseditpaper: false,
          source: "Preview Question",
        });
        try {
          const { data: res } = await editQuestionPaper(req);
          if (res.success == true) {
            notifySuccess(res.message);
            if (!_.isEmpty(res.response)) {
              if (res.response.rating) {
                this.setState({ showFeedbackForm: false });
              }
              const question_table_data = res.response.question_table_data;
              const total_ques_formed =
                question_table_data.total_generated_questions;
              const que_per_Page = question_table_data.perPage;
              const totalPages = Math.ceil(total_ques_formed / que_per_Page);
              const que_obj = question_table_data.questions;
              const page_no = question_table_data.page_no;

              this.setState({
                questions: [...que_obj],
                questionPaperGenerated: true,
                pageNo: page_no,
                totalPages: totalPages,
                isQuestionGenerated: true,
                totalQuestionsFormed: total_ques_formed,
              });
              this.scrollToQuestions();
            }
          } else {
            notifyError(res.message);
            this.resetQuestionState();
          }
        } catch (err) {
          console.log(err);
          this.resetQuestionState();
          if (err.response && err.response.status === 422) {
            const { data } = err.response;
            notifyError(data.msg);
            this.props.dispatch(authLogout());
          } else if (err.response && err.response.status === 401) {
            const { data } = err.response;
            notifyError(data.msg);
            this.props.dispatch(authLogout());
          } else {
            notifyError(MSG.commonApiError);
          }
        }
        this.props.dispatch(stopLoader());
      }
    }

    // if (this.props.isAuthenticated) {
    // await this.props.dispatch(fetchUser());
    // if (this.props.user.is_free_credit == 0) {
    //   try {
    //     const req = { credits: 41 };
    //     this.props.dispatch(startLoader());
    //     const { data: res } = await updateUserDetails(req);
    //     if (res.success == true) {
    //       if (res.code === 1111) {
    //         this.props.dispatch(fetchUser());
    //       }
    //     }
    //     this.props.dispatch(stopLoader());
    //   } catch (err) {
    //     console.log(err);
    //     this.resetQuestionState();
    //     if (err.response && err.response.status === 422) {
    //       const { data } = err.response;
    //       notifyError(data.msg);
    //       this.props.dispatch(authLogout());
    //     } else if (err.response && err.response.status === 401) {
    //       const { data } = err.response;
    //       notifyError(data.msg);
    //       this.props.dispatch(authLogout());
    //     } else {
    //       notifyError(MSG.commonApiError);
    //     }
    //   }
    // }
    // const refData = localStorage.getItem("refData");
    // if (refData) {
    //   this.props.history.replace("/plans");
    // } else {
    //   this.props.history.replace("/generate-questions-");
    // }
    // }

    if (this.props.user.country_code) {
      const country_code = _.lowerCase(this.props.user.country_code);
      this.setState((prevValue) => ({
        data: { ...prevValue.data, country_code: country_code },
      }));
    }
    this.timer = setInterval(() => {
      this.setState({
        timeLeft: this.calculateTimeLeft(),
      });
    }, 1000);

    if (this.props.user.is_instruction_readed == 0) {
      this.setState(
        {
          showAlert: true,
        },
        async () => {
          await updateInstructionRead();
          this.props.dispatch(fetchUser());
        }
      );
    }

    //trackEvent(("Generate Question Page Loaded", { source: this.state.source });
    //timeEvent("Leaving Generate Question Page");
  }

  componentDidUpdate() {
    window.onclick = () => {
      if (!this.state.firstEventScroll && this.state.activity_count == 1) {
        //trackEvent(("First Activity - Click");
      }
    };

    window.onscroll = (e) => {
      // console.log("Scroll");
      if (
        !this.state.firstEventScroll &&
        this.state.activity_count == 0 &&
        this.state.source == "Direct"
      ) {
        //trackEvent(("First Activity - Scroll");
        this.setState({
          firstEventScroll: true,
        });
      }

      if (this.state.scrollAfterGenerate) {
        //trackEvent(("Scroll After Question Generation");
        this.setState({
          scrollAfterGenerate: false,
        });
      }
      // Detect user scroll to the bottom of the page.
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        const element = document.getElementById("generated_questions");
        if (element && this.state.source == "Direct") {
          //trackEvent(("Scroll Down To Bottom - Generate Question");
        }
      }
    };

    if (this.props.isAuthenticated) {
      const refData = localStorage.getItem("refData");
      if (refData) {
        this.props.history.replace("/plans");
      }
    }
  }

  componentWillUnmount() {
    window.onscroll = () => {};
    window.onclick = () => {};
  }

  tabChanged = (e) => {
    this.props.dispatch(setInitialQues());
    this.setState({ currentActiveTab: e });
  };

  redirectToPlansPage = () => {
    this.props.history.push("/plans-bloom");
  };

  handleCallback = (childData) => {
    let ques_count = {
      easyCount: 0,
      mediumCount: 0,
      hardCount: 0,
      descriptiveCount: 0,
      trueFalseCount: 0,
      fillUpsCount: 0,
    };
    ques_count.easyCount = childData.total_easy_mcq;
    ques_count.mediumCount = childData.total_medium_mcq;
    ques_count.hardCount = childData.total_hard_mcq;
    ques_count.trueFalseCount = childData.total_true_false;
    ques_count.descriptiveCount = childData.total_descriptive;
    ques_count.fillUpsCount = childData.total_fill_ups;

    var que_obj = childData.questions;
    this.setState({
      questions: [...que_obj],
      ques_count: { ...ques_count },
      pageNo: childData.page_no,
      totalPages: childData.page_no,
    });
    // console.log(this.state)

    // if (this.state.questions.length < this.state.requiredQuestionCount) {
    //   this.setState({ lessQuestionsGenerated: true });
    // }
  };

  onInputChange = (e) => {
    this.setState({ tmpselectedWikiTopic: e.target.value });
  };

  resetQuestionState = () => {
    this.setState({
      questions: [],
      questionPaperGenerated: false,
      ques_count: {
        easyCount: 0,
        mediumCount: 0,
        hardCount: 0,
        descriptiveCount: 0,
        trueFalseCount: 0,
        fillUpsCount: 0,
      },
    });
  };

  scrollToQuestions = () => {
    const position = document.getElementById("generated_questions").offsetTop;
    window.scrollTo(0, position);
  };

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  handleAddQuestionOnClick = () => {
    this.addquestion();
    this.scrollToBottom();
  };

  countWords = (str) => {
    const arr = str.split(" ");
    return arr.filter((word) => word !== "").length;
  };

  validateInput = () => {
    const { subject_name, key_pcma, topic_formula_name } = this.state;
    let ret = true;
    if (!subject_name) {
      this.setState({
        errorEvent: "subject_name",
        errorMsg: "Please select a Subject Name",
      });
      return false;
    } else if (_.isEmpty(subject_name.label)) {
      this.setState({
        errorEvent: "subject_name",
        errorMsg: "Please select a Subject Name",
      });
      ret = false;
    } else if (!key_pcma) {
      this.setState({
        errorEvent: "topic_or_formula",
        errorMsg: "Please select an Option Topic/Formula Based",
      });
      ret = false;
    } else if (!topic_formula_name) {
      this.setState({
        errorEvent: "topic_formula",
        errorMsg:
          key_pcma === 2 ? "Please select a Formula" : "Please select a Topic",
      });
      return false;
    } else if (_.isEmpty(topic_formula_name.label)) {
      this.setState({
        errorEvent: "topic_formula",
        errorMsg:
          key_pcma === 2 ? "Please select a Formula" : "Please select a Topic",
      });
      ret = false;
    }
    return ret;
  };

  handleGenerateQuestion = async (e) => {
    if (
      this.props.user.credits <= 1 &&
      !this.props.user.plan_details.is_new_pricing
    ) {
      this.props.history.push("/plans-bloom");
      return;
    }

    if (!this.validateInput()) {
      return;
    }

    if (this.props.user.update_profile_index) {
      if (this.state.showRatingAlert) {
        this.setState({
          isShowProfilePopUp: true,
        });
      } else {
        this.setState({
          showUpdateProfilePopUp: true,
        });
      }
    }

    const current_date = moment(moment().toDate()).format("YYYY-MM-DD");
    const plan_expiry_date = moment(this.props.user.plan_expired_at).format(
      "YYYY-MM-DD"
    );

    if (
      moment(plan_expiry_date).isBefore(current_date) &&
      this.props.user.plan_details.plan_name != "Free"
    ) {
      // notifyError("Your Premium plan is expired. Please renew your plan.");
      this.setState({
        premiumPromptText:
          "Your Premium plan is expired. Renew your plan to continue generating more question papers.",
        isShowPremiumPrompt: true,
      });
      if (this.state.showRatingAlert == false) {
        this.setState({
          showPremiumPrompt: true,
        });
      }
      return;
    }

    this.props.dispatch(startLoader());
    let device = "Desktop";
    if (isMobile == true) {
      device = "Mobile";
    }

    const { subject_name, key_pcma, topic_formula_name, level_pcma } =
      this.state;
    const { with_diagram } = this.props.customQuesType;
    let myformData = new FormData();
    myformData.append("device", device);
    myformData.append("subject_name", subject_name.label);
    myformData.append("key_pcma", key_pcma);
    myformData.append("topic_pcma", topic_formula_name.label);
    myformData.append("level_pcma", level_pcma);
    myformData.append("with_diagram", with_diagram);
    try {
      const { data: res } = await GQSubjectExpertise(myformData);
      if (res.success == true) {
        notifySuccess(res.message);
        this.props.dispatch(fetchUser());

        if (!_.isEmpty(res.response)) {
          const total_ques_formed = res.response.total_generated_questions;
          const que_per_Page = res.response.perPage;
          const totalPages = Math.ceil(total_ques_formed / que_per_Page);
          const que_obj = res.response.questions;

          this.setState({
            questions: [...que_obj],
            questionPaperGenerated: true,
            pageNo: res.response.page_no,
            totalPages: totalPages,
            isQuestionGenerated: true,
            totalQuestionsFormed: total_ques_formed,
          });

          this.scrollToQuestions();
        }
      } else {
        if(res.code === 429){
          this.props.dispatch(showRequestInQueuePrompt());
          // notifyWarning(res.message)
       }
       else {
        notifyError(res.message);
        if (this.props.user.plan_details.is_new_pricing) {
          if (res.code === 1199) {
            this.setState({
              showRatingAlert: false,
              showAddOn: true,
              activatePlan: this.props.addOnsList.Free,
              addOnText: textData.FreeAddOnText,
              addOnCTAText: textData.AddOnCTAText4,
              freeAddOnSubText: textData.FreeAddOnSubText.replace(
                "{price}",
                this.props.addOnsList.Free.currency_symbol +
                  this.props.addOnsList.Free.price
              ),
              showProgressBar: false,
              contentId: null,
              progress_percent: 0,
            });
          }

          if (this.props.user.plan_details.active_subscription) {
            if (res.code === 1031) {
              this.setState({
                showRatingAlert: false,
                showTopUp: true,
              });
            }
          }
        }
        }
      }
    } catch (err) {
      // //trackEvent(("Questions Generation Failed - Type/Paste Text");
      console.log(err);
      this.resetQuestionState();
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.props.dispatch(stopLoader());
  };

  //GET Timestamp for the video files
  startTimeStampHandler = (secs) => {
    this.setState({ startTime: secs });
  };

  endTimeStampHandler = (secs) => {
    this.setState({ endTime: secs });
  };

  isCustomDurationHandler = () => {
    this.setState({ isCustomDuration: true });
  };

  startTypingTime = () => {
    if (this.state.timeInputMsg) {
      this.setState({ timeInputMsg: null });
    }
  };

  handleClearCustomDuration = () => {
    this.setState({
      isCustomDuration: false,
      timeInputMsg: null,
      startTime: null,
      endTime: null,
    });
  };

  validateTimeInput = () => {
    const { startTime, endTime } = this.state;
    if (!startTime || !endTime) {
      return true;
    } else if (startTime === endTime) {
      this.setState({ timeInputMsg: "Start & End time can't be same" });
      return false;
    } else if (startTime > endTime) {
      this.setState({ timeInputMsg: "End time can't be less than Start time" });
      return false;
    }
    return true;
  };

  scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  handleChange = ({ currentTarget: input }) => {
    if (this.state.showWordLimit) this.setState({ showWordLimit: false });
    this.setState({
      [input.name]: input.value,
    });
  };

  handleTitleChange = ({ currentTarget: input }) => {
    if (_.trim(input.value) != "") {
      this.setState({
        titleofQuestion_emsg: "",
      });
    }
    this.setState({
      [input.name]: input.value,
    });
  };

  handleQuestionUpdate = (question) => {
    try {
      this.setState(
        produce(this.state, (draftState) => {
          const index = draftState.questions.findIndex(
            (m) => m.question_id === question.question_id
          );
          draftState.questions[index] = question;
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  handlestateforaddquestion = (newquestion) => {
    switch (newquestion.category_type) {
      case 1:
        this.state.ques_count.easyCount += 1;
        break;
      case 2:
        this.state.ques_count.mediumCount += 1;
        break;
      case 3:
        this.state.ques_count.hardCount += 1;
        break;
      case 4:
        this.state.ques_count.trueFalseCount += 1;
        break;
      case 5:
        this.state.ques_count.descriptiveCount += 1;
        break;
      case 6:
        this.state.ques_count.fillUpsCount += 1;
        break;
      default:
        break;
    }
    this.setState({
      questions: [...this.state.questions, newquestion],
    });
  };

  addquestion = () => {
    // console.log("op", this.state.questiontypetab);
    //trackEvent(("Add Question Button Clicked", {
    //   category: this.state.questiontypetab,
    //   source: this.state.source,
    // });

    switch (this.state.questiontypetab) {
      case "knowledge":
        this.setState({ addeasymcq: !this.state.addeasymcq });
        break;
      case "Comprehension":
        this.setState({ addmediummcq: !this.state.addmediummcq });
        break;
      case "Application":
        this.setState({ addhardmcq: !this.state.addhardmcq });
        break;
      case "Analysis":
        this.setState({ addtruefalse: !this.state.addtruefalse });
        break;
      case "Evaluation":
        this.setState({ adddescriptive: !this.state.adddescriptive });
        break;
      case "Synthesis":
        this.setState({ addfillups: !this.state.addfillups });
        break;
    }
  };

  handlediscard = (questiontype) => {
    switch (questiontype) {
      case "knowledge":
        this.setState({ addeasymcq: !this.state.addeasymcq });
        break;
      case "Comprehension":
        this.setState({ addmediummcq: !this.state.addmediummcq });
        break;
      case "Application":
        this.setState({ addhardmcq: !this.state.addhardmcq });
        break;
      case "Analysis":
        this.setState({ addtruefalse: !this.state.addtruefalse });
        break;
      case "Evaluation":
        this.setState({ adddescriptive: !this.state.adddescriptive });
        break;
      case "Synthesis":
        this.setState({ addfillups: !this.state.addfillups });
        break;
    }
  };

  handleQPRating = async () => {
    if (this.state.questionPaperRating == null) {
      notifyError("Please Rate Question Paper First!");
      return false;
    }
    this.setState({
      showRatingAlert: false,
      feedbackAlert: false,
      showFeedbackForm: false,
    });

    var QP_Rating_Parm = {};

    var content_id = this.state.questions[0].content_id;
    if (this.state.ratingComment != "") {
      QP_Rating_Parm = {
        content_id: content_id,
        rating: this.state.questionPaperRating,
        comment: this.state.ratingComment,
      };
    } else {
      QP_Rating_Parm = {
        content_id: content_id,
        rating: this.state.questionPaperRating,
      };
    }
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await QuestionPaperRating(QP_Rating_Parm);
      if (res.success == true) {
        notifySuccess(res.message);
        this.setState({
          questionPaperRating: null,
          highlightBadEmoji: null,
          highlightNeutralEmoji: null,
          highlightGoodEmoji: null,
          showFeedbackForm: false,
          showRatingAlert: false,
        });
        this.handleRatingPromptCloseEvent();
        //this.setState({ showRatingAlert: false, feedbackAlert: false });
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.props.dispatch(stopLoader());
  };

  handleQPRatingChange = (value) => {
    this.setState({
      questionPaperRating: value,
    });
  };

  getRateText = (rate) => {
    if (rate == 1) {
      document.getElementById("labelFeedbackLabel").innerHTML = "Not at All";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else if (rate == 2) {
      document.getElementById("labelFeedbackLabel").innerHTML =
        "To Some Extent";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else if (rate == 3) {
      document.getElementById("labelFeedbackLabel").innerHTML = "Yes, a Lot";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else {
      document.getElementById("labelFeedbackLabel").innerHTML = "";
      document.getElementById("labelFeedbackLabel").style.width = "0";
    }
  };

  handleRatingPromptCloseEvent = () => {
    this.setState({ showRatingAlert: false, feedbackAlert: false });
    if (this.state.isShowPremiumPrompt) {
      this.setState({
        showPremiumPrompt: true,
      });
    }
  };

  handlePagination = async (req) => {
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await addPagination(req);
      if (res.success == true) {
        notifySuccess(res.message);
        const que_obj = res.response.questions;
        if (!_.isEmpty(que_obj)) {
          const total_ques_formed = res.response.total_generated_questions;
          const que_per_Page = res.response.perPage;
          const totalPages = Math.ceil(total_ques_formed / que_per_Page);
          const que_obj = res.response.questions;

          this.setState({
            questions: [...que_obj],
            questionPaperGenerated: true,
            pageNo: res.response.page_no,
            totalPages: totalPages,
            isQuestionGenerated: true,
            totalQuestionsFormed: total_ques_formed,
          });

          this.scrollToQuestions();
          await this.sleep(1000);
          this.setState({
            scrollAfterGenerate: true,
          });
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.props.dispatch(stopLoader());
  };

  nextPage = () => {
    if (this.state.pageNo == this.state.totalPages) {
      return;
    }
    var pageNo = this.state.pageNo + 1;
    var que_obj = this.state.questions[0];

    var req = {
      content_id: que_obj.content_id,
      page_no: pageNo,
      is_sub_expertise: 1,
    };

    this.handlePagination(req);
  };

  previousPage = () => {
    if (this.state.pageNo == 1) {
      return;
    }
    var pageNo = this.state.pageNo - 1;
    var que_obj = this.state.questions[0];

    var req = {
      content_id: que_obj.content_id,
      page_no: pageNo,
      is_sub_expertise: 1,
    };
    this.handlePagination(req);
  };

  // For testing Referral Popup
  // handlePopupOpen = () => {
  //   this.props.dispatch(
  //     showReferral({
  //       title: "Want to extend your plan for free?",
  //       content: "Invite your friends and get 7 days of Premium Plan added",
  //     })
  //   );
  // };

  handleClose = () => {
    this.props.dispatch(closeReferral());
  };

  updateActivityCount = (e) => {
    if (e.target.className == "dzu-input") {
      var increment = this.state.activity_count + 1;
      this.setState({
        activity_count: increment,
      });
    }
  };

  handleOnChange = (value, data, event, formattedValue) => {
    if (value !== undefined) {
      this.setState({
        data: {
          ...this.state.data,
          contact_number: value,
          formatted_phone_number: formattedValue,
          country_code: data.countryCode,
          dial_code: "+" + data.dialCode,
        },
      });
    }
  };

  handleUpdateProfileChange = ({ currentTarget: input }) => {
    this.setState({
      [input.name]: input.value,
    });
  };

  CancelUpdateProfile = async () => {
    this.setState({
      showLoader: !this.state.showLoader,
    });

    try {
      const { data: res } = await updateUserDetails({
        ask_later: 1,
      });
      if (res.success == true) {
        await this.props.dispatch(fetchUser());
        if (this.props.user.update_profile_index == 0) {
          notifySuccess(res.message);
          this.setState({
            showUpdateProfilePopUp: false,
            isShowProfilePopUp: false,
          });
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
  };

  UpdateProfileSubmit = async () => {
    if (this.state.profession == "") {
      notifyError("Please select an option under profession section");
      return;
    } else if (
      this.state.profession == "Others" &&
      this.state.profession_other == ""
    ) {
      notifyError("Please enter your profession");
      return;
    } else if (this.state.source_from == "") {
      notifyError("Please select an option under source section");
      return;
    } else if (
      this.state.source_from == "Others" &&
      this.state.source_from_other == ""
    ) {
      notifyError("Please enter your profession");
      return;
    } else if (this.state.data.contact_number == "") {
      notifyError("Please enter your phone details");
      return;
    }

    let reqObj = this.state.data;
    let req = {
      ...reqObj,
      profession: this.state.profession,
      profession_other: this.state.profession_other,
      source_from: this.state.source_from,
      source_from_other: this.state.source_from_other,
    };
    req.contact_number = req.contact_number.substring(req.dial_code.length - 1);

    this.setState({
      showLoader: !this.state.showLoader,
    });

    try {
      const { data: res } = await updateUserDetails(req);
      if (res.success == true) {
        console.log(res);
        await this.props.dispatch(fetchUser());
        if (this.props.user.update_profile_index == 0) {
          notifySuccess(res.message);
          this.setState({
            showUpdateProfilePopUp: false,
            isShowProfilePopUp: false,
          });
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
  };

  OpenReviewPopUp = () => {
    this.setState({
      showRatingAlert: true,
    });
  };

  handleRatingComment = (e) => {
    this.setState({
      ratingComment: e.target.value,
    });
  };

  setSubject = (subject) => {
    // this.props.setQuesType(ques_type)
    this.setState({
      subject_name: subject,
      topic_formula: [],
      typeOfQuestion_name: "",
      topic_formula_name: "",
      errorEvent: "",
      key_pcma: "",
    });

    let topic_formula = [];
    let topic_formula_name = {};
    let key_pcma = 0;
    let disableTopicFormula = false;

    switch (subject.value) {
      case "Mathematics":
        topic_formula = MATHS_TOPIC;
        topic_formula_name = MATHS_TOPIC[0];
        key_pcma = 1;
        break;
      case "Physics":
        topic_formula = PHYSICS_TOPIC;
        topic_formula_name = PHYSICS_TOPIC[0];
        key_pcma = 1;
        break;
      case "Chemistry":
        topic_formula = CHEMISTRY_TOPIC;
        topic_formula_name = CHEMISTRY_TOPIC[0];
        key_pcma = 1;
        break;
      case "Aptitude":
        topic_formula = APTITUDE_TOPIC;
        topic_formula_name = APTITUDE_TOPIC[0];
        key_pcma = 3;
        disableTopicFormula = true;
        break;
      default:
        break;
    }
    this.setState({
      // typeOfQuestion_name: type,
      topic_formula,
      topic_formula_name,
      key_pcma,
      errorEvent: "",
      disableTopicFormula,
    });
  };

  setTypeOfQuestion = (type) => {
    const { subject_name } = this.state;

    if (!type) {
      return;
    }

    this.setState({ topic_formula_name: "" });

    const subject = subject_name.value;
    let topic_formula = [];
    let topic_formula_name = {};
    let key_pcma = 0;

    switch (type.value) {
      case "Topic Based":
        switch (subject) {
          case "Mathematics":
            topic_formula = MATHS_TOPIC;
            topic_formula_name = MATHS_TOPIC[0];
            key_pcma = 1;
            break;
          case "Physics":
            topic_formula = PHYSICS_TOPIC;
            topic_formula_name = PHYSICS_TOPIC[0];
            key_pcma = 1;
            break;
          case "Chemistry":
            topic_formula = CHEMISTRY_TOPIC;
            topic_formula_name = CHEMISTRY_TOPIC[0];
            key_pcma = 1;
            break;
          case "Aptitude":
            topic_formula = APTITUDE_TOPIC;
            topic_formula_name = APTITUDE_TOPIC[0];
            key_pcma = 3;
            break;
          default:
            break;
        }
        break;
      case "Formula Based":
        switch (subject) {
          case "Aptitude":
            key_pcma = 0;
            break;
          case "Mathematics":
            topic_formula = MATHS_FORMULA;
            topic_formula_name = MATHS_FORMULA[0];
            key_pcma = 2;
            break;
          case "Physics":
            topic_formula = PHYSICS_FORMULA;
            topic_formula_name = PHYSICS_FORMULA[0];
            key_pcma = 2;
            break;
          case "Chemistry":
            topic_formula = CHEMISTRY_FORMULA;
            topic_formula_name = CHEMISTRY_FORMULA[0];
            key_pcma = 2;
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    this.setState({
      typeOfQuestion_name: type,
      topic_formula,
      topic_formula_name,
      key_pcma,
      errorEvent: "",
    });
  };

  setTopicFormula = (topic) => {
    this.setState({ topic_formula_name: topic, errorEvent: "" });
  };

  radioButtonValue = (event) => {
    const { name } = event.target;
    if (name === "topic_") {
      this.setState(
        {
          typeOfQuestion_name: { value: "Topic Based", label: "Topic Based" },
        },
        () => this.setTypeOfQuestion(this.state.typeOfQuestion_name)
      );
    } else {
      this.setState(
        {
          typeOfQuestion_name: {
            value: "Formula Based",
            label: "Formula Based",
          },
        },
        () => this.setTypeOfQuestion(this.state.typeOfQuestion_name)
      );
    }
  };

  getSortedOptions = () => {
    let sortedArray = [];
    try {
      if (this.state.topic_formula) {
        let newValues = this.state.topic_formula;
        sortedArray = newValues
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
      }
    } catch (err) {
      console.log(err);
    }
    return sortedArray;
  };

  displayStripe = async (plan) => {
    if (plan.plan_name === "Free") {
      this.props.history.replace("/plans");
      return;
    }

    this.setState({
      showPrompt: false,
      showEnterprisePrompt: false,
      showLoader: !this.state.showLoader,
    });
    this.props.dispatch(startLoader());

    try {
      if (plan.plan_name === "Free") {
        var api_name = purchaseFreePlan;
        var req = {
          plan_id: plan.plan_id,
        };
      } else {
        var api_name = purchaseAddOns;
        var req = {
          add_on_id: plan.plan_id,
        };
      }

      const { data: res } = await api_name(req);
      if (res.success == true) {
        notifySuccess(res.message);
        // this.props.dispatch(setSelectedPlan(plan));
        const clientSecret = res.response.clientSecret;
        var payment_details = {
          clientSecret: clientSecret,
          amount: res.response.amount,
          is_coupon_applied: res.response.is_coupon_applied,
          coupon: res.response.coupon,
          currency: res.response.currency,
        };
        this.props.dispatch(setSelectedPlan(payment_details));
        if (clientSecret) {
          this.setState({
            // selectedPlan: { ...plan },
            stripePromise: loadStripe(this.state.stripeKey),
            showStripeForm: true,
            stripeClientSecret: clientSecret,
            showAddOn: false,
          });
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
    this.props.dispatch(stopLoader());
  };

  questionPaperTopUPCallBack = (data) => {
    if (data.hidePopUp) {
      this.setState({
        showTopUp: !data.hidePopUp,
      });
    }
  };

  handleSubsetCallBackExpertise = async (data) => {
    if (!data.showPopUp) {
      this.setState({
        showSetsPopUpExpertise: data.showPopUp,
      });
    }
  };

  handleDownloadFile = async (type, answer = 0) => {
    const item = {
      topic: this.state.questions[0]?.topic,
      content_id: this.state.questions[0]?.content_id,
    };
    if (type == "xlsx") {
      answer = 0;
    }
    if (
      (type == "docx" &&
        !this.props.user.plan_details.download_doc_is_allowed) ||
      (type == "xlsx" &&
        !this.props.user.plan_details.download_excel_is_allowed)
    ) {
      return;
    }
    /*
    if (type == "pdf" && !item.plan_details.download_pdf_is_allowed) {
      notifyError("Download not allowed for this content in your plan");
      return;
    }
    if (type == "docx" && !item.plan_details.download_pdf_is_allowed) {
      notifyError("Download not allowed for this content in your plan");
      return;
    }
    if (type == "xlsx" && !item.plan_details.download_pdf_is_allowed) {
      notifyError("Download not allowed for this content in your plan");
      return;
    } */

    var add_on_name = null;

    this.props.dispatch(startLoader());
    if (type == "pdf") {
      trackEvent("Download PDF - Preview Question Page");
      var inputFiletype = "pdf";
      var fileName = item.topic + ".pdf";
      var mimeType = "application/pdf";
    } else if (type == "docx") {
      trackEvent("Download Doc - Preview Question Page");
      var inputFiletype = "word";
      var fileName = item.topic + ".docx";
      var mimeType = "application/msword";
      add_on_name = "Download-Doc";
    } else if (type == "xlsx") {
      trackEvent("Download Excel - Preview Question Page");
      var inputFiletype = "excel";
      var fileName = item.topic + ".xlsx";
      var mimeType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      add_on_name = "Download-Excel";
    } else if (type == "json") {
      trackEvent("Download Json - Preview Question Page");
      var inputFiletype = "json";
      var fileName = item.topic + ".json";
      var mimeType =
        "application/json";
    }

    try {
      const { data: respose } = await validationForDownloadQuePaper({
        content_id: item.content_id,
        filetype: inputFiletype,
        isDownload: 1,
      });
      if (respose.success == true) {
        /*if (type == "pdf") { */
        if (respose.code == 1111) {
          this.props.dispatch(fetchUser());
        }
        try {
          const res = await seDownloadQuestionPaper({
            content_id: item.content_id,
            filetype: inputFiletype,
            answer,
          });
          if (res.status != 200) {
            notifyError(res.message);
          } else {
            var blob = new Blob([res.data], { type: mimeType });
            saveAs(blob, fileName);
          }
        } catch (err) {
          console.log("err", err);
          notifyError(MSG.commonApiError);
        }
      } else {
        notifyError(respose.message);
        if (this.props.user.plan_details.active_subscription) {
          let active_plan = this.props.user.plan_details.plan_name;
          // 1099 code means purchase add ons to use this feature.
          if (respose.code == 1099) {
            this.setState({
              showAddOn: true,
              activatePlan: this.props.addOnsList[add_on_name][active_plan],
              addOnText: textData.DownloadDocumentAddOnText.replace(
                "{price}",
                this.props.addOnsList[add_on_name][active_plan]
                  .currency_symbol +
                  this.props.addOnsList[add_on_name][active_plan].price
              ),
              addOnCTAText: textData.AddOnCTAText3,
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
      notifyError(MSG.commonApiError);
    }

    this.props.dispatch(stopLoader());
  };

  handleCreateTest = async () => {
    if (!this.props.user.plan_details.is_group_assessment) {
      return;
    }
    this.props.dispatch(startLoader());
    var content_id = this.state.questions[0].content_id;
    try {
      const { data: res } = await createTest({
        content_id: content_id,
      });
      if (res.success == true) {
        notifySuccess(res.message);
        this.props.history.push({
          pathname: "/test-preview-subject",
          state: {
            content_id: content_id,
            test_id: res.response.test_id,
          },
        });
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  };

  beforeAssessment = () => {
    const { content_id, topic } = this.state.questions[0];
    this.setState({
      showDurationAlert: true,
      currentContentId: content_id,
      currentTopicName: topic,
    });
  };

  callbackFunc = (topic, show) => {
    this.setState({ showDurationAlert: false, currentTopicName: topic });
  };

  checkOptionsExist = () => {
    const question = this.state.questions[0];
    if (_.isArray(question.options) && question.options.length > 0) {
      return true;
    }
    return false;
  };

  handleSetState = (state) => {
    this.setState(state);
  };

  render() {
    let que_number_start_pt = this.state.pageNo * 10 - 10; // ques number for first question of every page say 1 for page one 11 for page 2
    let countQues = que_number_start_pt;
    const is_new_pricing =
      !_.isEmpty(this.props.user) &&
      this.props.user.plan_details.is_new_pricing;
    return (
      <React.Fragment>
        <Tooltip title="Coming Soon" id="global" />
        <div
          className={classNames(
            styles["mobile-height-cover"],
            "height-cover bg-e5"
          )}
        >
          <div className="bg--overlay--top"></div>
          <div
            className={classNames(
              styles["container-custom-margin"],
              "container mb-4 position-relative"
            )}
          >
            {/* {this.props.user.plan_details && (
              <div
                className="notify-tool-tip"
                style={{
                  position: "absolute",
                  top: "9rem",
                  right: "17.5rem",
                }}
              >
                <i
                  className="fas fa-info-circle instructions"
                  style={{ fontSize: "20px", color: "#a1bfe8" }}
                ></i>
                {this.props.user.plan_details.plan_name == "Free" ? (
                  <span
                    className="hoverText"
                    style={{ color: "#b6b1b1", backgroundColor: "#11267b" }}
                  >
                    You've{" "}
                    {this.props.user.credits <= 0
                      ? "0 free credits"
                      : this.props.user.credits}{" "}
                    free credits worth{" "}
                    {this.props.user.credits > 0 &&
                      `$${_.round(this.props.user.credits / 2, 1)}`}{" "}
                    available in your account.
                    <br />
                    <span style={{ color: "white" }}>
                      Upgrade to PrepAI Premium
                    </span>{" "}
                    to get 54 free credits worth $27
                  </span>
                ) : (
                  <span className="hoverText">
                    Credits Available:{" "}
                    {this.props.user.credits <= 0 ? 0 : this.props.user.credits}{" "}
                  </span>
                )}
              </div>
            )} */}
            {/* <Toggle blooms={true} /> */}

            <div
              className={classNames(
                styles["generate--bg--title"],
                "generate--bg--title"
              )}
            >
              <h3>Generate Advanced Numerical Questions</h3>
              <span>Based on CBSE Curriculum</span>
            </div>
            <ToggleForGQ
              tab="Special"
              credits={this.props.user.credits}
              plan_details={this.props.plan_details}
            />
            <GenerateQuestionSpecialist
              state={this.state}
              setState={this.handleSetState}
              props={this.props}
              is_new_pricing={is_new_pricing}
              setSubject={this.setSubject}
              radioButtonValue={this.radioButtonValue}
              getSortedOptions={this.getSortedOptions}
              setTopicFormula={this.setTopicFormula}
              handleGenerateQuestion={this.handleGenerateQuestion}
            />
          </div>
        </div>

        {this.state.questionPaperGenerated && (
          <div className="section--padding bg-e5" id="generated_questions">
            <div
              className={classNames(
                "container",
                styles["speciliast-custom-container"]
              )}
            >
              <div className="row justify-content-center">
                <div className="col-md-10 mb-4">
                  <div
                    className={classNames(
                      styles["generated-questions-top-section"],
                      "row"
                    )}
                    style={{ marginRight: "0" }}
                  >
                    <div className="col-md-4">
                      <p
                        className={classNames(
                          styles["section-generated-title-responsive"],
                          "section-generated-title"
                        )}
                        // style={{ marginLeft: "29.5%" }}
                      >
                        Generated Questions
                      </p>
                    </div>
                    <div
                      className={classNames(
                        styles["front-action-buttons-responsive"],
                        "col-md-8 front-action-buttons pr-3"
                      )}
                      // style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {this.checkOptionsExist() && (
                        <div
                          className="nav-item dropdown fl-center"
                          // style={{
                          //   justifyContent: "flex-end",
                          //   paddingRight: "2%",
                          // }}
                        >
                          <a
                            className={classNames(
                              styles["front-action-buttons-text-responsive"],
                              "nav-link dropdown-toggle dropdown-title-front"
                            )}
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Conduct Test
                          </a>
                          <div
                            className={classNames(
                              styles["conduct-test-dropdown"],
                              "dropdown-menu navdrop--menu dropdown-main"
                            )}
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              to="#"
                              onClick={this.beforeAssessment}
                              className={classNames(
                                styles["front-action-buttons-text-responsive"],
                                "dropdown-item"
                              )}
                            >
                              For Self
                            </Link>
                            <Link
                              to="#"
                              onClick={this.handleCreateTest}
                              className={classNames(
                                styles["front-action-buttons-text-responsive"],
                                `dropdown-item ${
                                  !_.isEmpty(this.props.user) &&
                                  !this.props.user.plan_details
                                    .is_group_assessment
                                    ? "sm-disabled"
                                    : ""
                                }`
                              )}
                            >
                              For Others
                              {/* {is_new_pricing === 1 && (
                                <img
                                  src="img/crown.jpg"
                                  alt="icon"
                                  width="30"
                                  height="30"
                                  style={{
                                    position: "absolute",
                                    marginTop: "-12px",
                                    marginLeft: "-7px",
                                  }}
                                />
                              )} */}
                            </Link>
                          </div>
                        </div>
                      )}
                      <div
                        className=""
                        style={
                          {
                            // paddingRight: "2%",
                          }
                        }
                      >
                        <Link
                          type="button"
                          role="button"
                          style={{ textDecoration: "none" }}
                          className={classNames(
                            styles["front-action-buttons-text-responsive"],
                            "preview--paper--btn"
                          )}
                          to="#"
                          // to={{
                          //   pathname: "/question-paper-preview-expertise",
                          //   state: this.state.questions[0],
                          // }}
                          // onClick={(e) =>
                          //   trackEvent("Preview Question Paper Clicked", {
                          //     source: this.state.source,
                          //   })
                          // }
                          onClick={() => this.setState({ showPreview: true })}
                        >
                          Preview Quiz
                        </Link>
                      </div>
                      <div
                        className=""
                        style={
                          {
                            // paddingRight: "2%",
                          }
                        }
                      >
                        <Link
                          type="button"
                          role="button"
                          style={{ textDecoration: "none" }}
                          className={classNames(
                            styles["front-action-buttons-text-responsive"],
                            "preview--paper--btn"
                          )}
                          to={"#"}
                          onClick={() =>
                            this.setState({
                              showSetsPopUpExpertise: true,
                            })
                          }
                          disabled
                        >
                          Generate Sets
                        </Link>
                        {this.state.showSetsPopUpExpertise && (
                          <QuestionPaperSetsExpertise
                            callBack={this.handleSubsetCallBackExpertise}
                            data={{
                              show: true,
                              content_id: this.state.questions[0].content_id,
                              que_count: this.state.totalQuestionsFormed,
                            }}
                          />
                        )}
                      </div>
                      <div className="">
                        <Link
                          type="button"
                          role="button"
                          style={{ textDecoration: "none" }}
                          className={classNames(
                            styles["front-action-buttons-text-responsive"],
                            "preview--paper--btn"
                          )}
                          to={"#"}
                          onClick={(e) => {
                            this.setState({ showDownloadPopUp: true });
                          }}
                        >
                          Download
                        </Link>
                        <DonwloadQuestionPaper
                          showDownloadPopUp={this.state.showDownloadPopUp}
                          closeDownloadPopUp={() =>
                            this.setState({ showDownloadPopUp: false })
                          }
                          downloadAllowed={this.state.currentItem}
                          isExpertise={true}
                          downloadQuestionPaper={this.handleDownloadFile}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(styles["custom-width"], "col-md-10")}
                >
                  <div
                    className={classNames(
                      styles["qna-wrapper"],
                      "question-wrapper--outer"
                    )}
                  >
                    {this.state.totalQuestionsFormed == 0 && (
                      <p>No Questions Formed!</p>
                    )}
                    {this.state.questions.map((question) => {
                      countQues += 1;
                      return (
                        <SubjectExpertise
                          key={`question_${question.question_id}`}
                          question={question}
                          count={countQues}
                          handleQuestionUpdate={this.handleQuestionUpdate}
                          data={{ source: this.state.source }}
                        />
                      );
                    })}
                  </div>
                  {this.state.totalQuestionsFormed != 0 && (
                    <div className="each-question-wrapper-box-pagination">
                      <div
                        className={classNames(
                          styles["flex-nowrap"],
                          "row align-items-center"
                        )}
                      >
                        <div className="col-md-4">
                          <p
                            className={classNames(
                              styles["pagination-text"],
                              "showing-page-results"
                            )}
                          >
                            Showing {this.state.pageNo}-{this.state.totalPages}{" "}
                            of {this.state.totalPages} items
                          </p>
                        </div>
                        <div className="col-md-8">
                          <ul className="list-unstyled-pagination">
                            <li
                              className={`prev-navigation show-pointer ${
                                this.state.pageNo == 1 ? "sm-disabled" : ""
                              }`}
                              onClick={this.previousPage}
                            >
                              <a>
                                <svg
                                  width="7"
                                  height="12"
                                  viewBox="0 0 7 12"
                                  fill="none"
                                >
                                  <path
                                    d="M5.64209 10.6667L0.999975 6.02457L5.64209 1.38246"
                                    stroke={
                                      this.state.pageNo == 1
                                        ? "#B7CCEB"
                                        : "#000000"
                                    }
                                    strokeWidth="1.3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </a>
                            </li>
                            <li className="page-count-navigation">
                              <a>{this.state.pageNo}</a>
                            </li>
                            <li
                              className={`next-navigation show-pointer ${
                                this.state.pageNo == this.state.totalPages
                                  ? "sm-disabled"
                                  : ""
                              }`}
                              onClick={this.nextPage}
                            >
                              <a>
                                <svg
                                  width="7"
                                  height="12"
                                  viewBox="0 0 7 12"
                                  fill="none"
                                >
                                  <path
                                    d="M1.35791 1.33331L6.00003 5.97543L1.35791 10.6175"
                                    stroke={
                                      this.state.pageNo == this.state.totalPages
                                        ? "#B7CCEB"
                                        : "#000000"
                                    }
                                    strokeWidth="1.3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {(this.state.showFeedbackForm || this.state.showRatingAlert) &&
              this.state.questionPaperGenerated && (
                <div className="question-generate-tab-outer maxw-820 feedback">
                  <button
                    type="button"
                    className="close close_buttons"
                    aria-label="Close"
                    style={{ outline: "none" }}
                    onClick={() =>
                      this.setState({
                        showFeedbackForm: false,
                        showRatingAlert: false,
                      })
                    }
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="swal-titile">
                    <h4>
                      {/* <span>Your Question Paper is being Generated.</span>
                      <br />
                      <span>
                        Meanwhile, what do you think of your recent Question
                        Paper?{" "}
                      </span> */}
                      <span>Found this question paper helpful?</span>
                    </h4>
                  </div>
                  <div className="rate--question large-star1">
                    <span className="feedbackLabel">
                      <span id="labelFeedbackLabel"></span>
                    </span>
                    <div className="line-height-0 d-flex justify-content-center">
                      <div id="bad" className="emoji-scale">
                        <span className="describeEmoji">Not at All</span>
                        <i
                          className={`emoji ${
                            this.state.questionPaperRating == 1 ? "fas" : "far"
                          } fa-frown highlight ${this.state.highlightBadEmoji}`}
                          onClick={() =>
                            this.setState({
                              questionPaperRating: 1,
                              highlightNeutralEmoji: null,
                              highlightGoodEmoji: null,
                              highlightBadEmoji: "highlight-emoji",
                            })
                          }
                        ></i>
                      </div>
                      <div id="neutral" className="emoji-scale">
                        <span
                          className="describeEmoji"
                          style={{ marginLeft: "0px" }}
                        >
                          To Some Extent
                        </span>
                        <i
                          className={`emoji ${
                            this.state.questionPaperRating == 2 ? "fas" : "far"
                          } fa-meh highlight ${
                            this.state.highlightNeutralEmoji
                          }`}
                          onClick={() =>
                            this.setState({
                              questionPaperRating: 2,
                              highlightBadEmoji: null,
                              highlightGoodEmoji: null,
                              highlightNeutralEmoji: "highlight-emoji",
                            })
                          }
                        ></i>
                      </div>
                      <div id="Good" className="emoji-scale">
                        <span className="describeEmoji">Yes, a Lot</span>
                        <i
                          className={`emoji ${
                            this.state.questionPaperRating == 3 ? "fas" : "far"
                          } fa-grin-alt highlight ${
                            this.state.highlightGoodEmoji
                          }`}
                          onClick={() =>
                            this.setState({
                              questionPaperRating: 3,
                              highlightBadEmoji: null,
                              highlightNeutralEmoji: null,
                              highlightGoodEmoji: "highlight-emoji",
                            })
                          }
                        ></i>
                      </div>
                    </div>
                    <TextField
                      id="outlined-basic"
                      placeholder="How could we make this better for you?"
                      variant="outlined"
                      fullWidth
                      value={this.state.ratingComment}
                      onChange={this.handleRatingComment}
                      style={{ width: "90%", marginLeft: "5%" }}
                    ></TextField>
                  </div>
                  <button
                    type="submit"
                    className="btn--submit"
                    onClick={this.handleQPRating}
                  >
                    Submit
                  </button>
                </div>
              )}
          </div>
        )}
        {/* <SweetAlert
        customClass="sweet-alert-rating"
          show={this.state.showRatingAlert}
          showConfirm
          showCloseButton
          style={{ zIndex: 99999999 }}
          title={
            <>
              <div className="swal-titile">
                <h4>
                  <span>Your Question Paper is being Generated.</span>
                  <br />
                  <span>
                    Meanwhile, what do you think of your recent Question Paper?{" "}
                  </span>
                </h4>
              </div>
              <div className="rate--question large-star1 mt-4">
                <span className="feedbackLabel">
                  <span id="labelFeedbackLabel"></span>
                </span>
                <div className="line-height-0 d-flex justify-content-center pt-4">
                  <div id="bad" className="emoji-scale">
                    <span className="describeEmoji">Bad</span>
                    <i
                      className={`emoji ${
                        this.state.questionPaperRating == 1 ? "fas" : "far"
                      } fa-frown highlight ${this.state.highlightBadEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 1,
                          highlightNeutralEmoji: null,
                          highlightGoodEmoji: null,
                          highlightBadEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                  <div id="neutral" className="emoji-scale">
                    <span className="describeEmoji">Neutral</span>
                    <i
                      className={`emoji ${
                        this.state.questionPaperRating == 2 ? "fas" : "far"
                      } fa-meh highlight ${this.state.highlightNeutralEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 2,
                          highlightBadEmoji: null,
                          highlightGoodEmoji: null,
                          highlightNeutralEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                  <div id="Good" className="emoji-scale">
                    <span className="describeEmoji">Good</span>
                    <i
                      className={`emoji ${
                        this.state.questionPaperRating == 3 ? "fas" : "far"
                      } fa-grin-alt highlight ${this.state.highlightGoodEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 3,
                          highlightBadEmoji: null,
                          highlightNeutralEmoji: null,
                          highlightGoodEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                </div>
              </div>
            </>
          }
          confirmBtnText="Submit"
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={this.handleRatingPromptCloseEvent}
          onOutsideClick={this.handleRatingPromptCloseEvent}
          onConfirm={this.handleQPRating}
          onCancel={this.handleRatingPromptCloseEvent}
        ></SweetAlert> */}
        {/* <SweetAlert
          customClass="sweet-alert"
          show={this.state.showAlert}
          showConfirm
          title={
            <div className="swal-title">
              <h2>Please Read Carefully</h2>
              <hr />
              <ol>
                <li className="instruction-points">
                  PrepAI Standard Mode - Works best with Subjective type contents and generates multiple varieties of questions.
PrepAI HOTS Mode - Works with a wider variety of contents and generates Higher Order Thinking questions.
PrepAI Specialist Mode - Build specifically for generating numerical questions for competitive exams.
                </li>
                <li className="instruction-points">
                  PrepAI generates quality questions on quality content. It may
                  not produce accurate results in cases where content is not
                  structured, or contains tabular data, diagrams, etc.
                </li>
                <li className="instruction-points">
                  PrepAI is available for content in English Language.
                </li>
              </ol>
            </div>
          }
          confirmBtnText="OK"
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showAlert: false })}
          onOutsideClick={() => this.setState({ showAlert: false })}
          onConfirm={() => this.setState({ showAlert: false })}
          onCancel={() => this.setState({ showAlert: false })}
        /> */}

        <SweetAlert
          customClass={classNames(
            styles["swal-preview"],
            "sweet-alert-preview"
          )}
          show={this.state.showPreview}
          showConfirm
          confirmBtnText="Okay"
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showPreview: false })}
          onOutsideClick={() => this.setState({ showPreview: false })}
          onConfirm={() => this.setState({ showPreview: false })}
          onCancel={() => this.setState({ showPreview: false })}
        >
          <button
            type="button"
            className={classNames(
              styles["swal-cross-btn"],
              "close sets-standard-close"
            )}
            aria-label="Close"
            style={{ outline: "none" }}
            onClick={() => this.setState({ showPreview: false })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <QuestionPaperPreviewExpertise
            location={{
              state: {
                topic: this.state.questions[0]?.topic,
                content_id: this.state.questions[0]?.content_id,
                ques_count: this.state.ques_count,
              },
            }}
            isModalPreview={true}
          />
        </SweetAlert>
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.showPremiumPrompt}
          showConfirm={false}
          title={
            <div className="swal-title">
              <p className="style-text">{this.state.premiumPromptText}</p>
              <Link className="btn prompt-plan-btn" to="/plans">
                Upgrade Plan
              </Link>
              {/* <span style={{ margin: "20px", fontSize: "20px" }}>OR</span>
              <Link className="btn prompt-referral-btn" to="/refer-prepai">
                Refer PrepAI
              </Link> */}
            </div>
          }
          onEscapeKey={() =>
            this.setState({
              showPremiumPrompt: false,
              isShowPremiumPrompt: false,
            })
          }
          onOutsideClick={() =>
            this.setState({
              showPremiumPrompt: false,
              isShowPremiumPrompt: false,
            })
          }
          onConfirm={() =>
            this.setState({
              showPremiumPrompt: false,
              isShowPremiumPrompt: false,
            })
          }
          onCancel={() =>
            this.setState({
              showPremiumPrompt: false,
              isShowPremiumPrompt: false,
            })
          }
        />

        <SweetAlert
          customClass="sweet-alert"
          show={window.innerWidth > 767 && this.state.showUpdateProfilePopUp}
          showConfirm
          showCancel
          title="Please complete your profile to continue"
          confirmBtnText="Finish"
          cancelBtnText="Ask Me Later"
          cancelBtnStyle={{ fontSize: "16px" }}
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showUpdateProfilePopUp: false })}
          onOutsideClick={() =>
            this.setState({ showUpdateProfilePopUp: false })
          }
          onConfirm={this.UpdateProfileSubmit}
          onCancel={this.CancelUpdateProfile}
          style={{ overflow: "initial", marginTop: "150px" }}
        >
          <form className='complete-profile mt-4'>
						{/* {this.props.user.update_profile_index == 1 && ( */}
						<div className='row my-2'>
							<label className='form--label col-6'>
								What best describes your role?*
							</label>

							<div
								className='select-list col-6'
								style={{fontSize: "larger"}}
							>
								<select
									name='profession'
									className='source_from custom-select'
									style={{width: "75%", height: "35px"}}
									required
									value={this.state.profession}
									onChange={this.handleUpdateProfileChange}
								>
									<option value=''>Select</option>
									{profession().map(op => {
										return (
											<option key={op} value={op}>
												{op}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						{
							this.state.profession === "Others" && (
								// this.props.user.update_profile_index == 1 && (
								<>
									{/* <hr className="mb-2" style={{width: "15%"}}/> */}
									<div className='row my-2'>
										<label
											htmlFor='profession_other'
											className='form--label col-6'
										>
											Please Specify*
										</label>
										<div className='col-6'>
											<input
												type='text'
												name='profession_other'
												id='profession_other'
												className='form-control'
												value={this.state.profession_other}
												onChange={this.handleChange}
												required
												style={{width: "75%", height: "35px"}}
											/>
										</div>
									</div>
								</>
							)
							// )
						}
						{/* )}{" "} */}
						{/* {this.props.user.update_profile_index == 2 && ( */}
						{/* <hr className='my-2' style={{width: "15%"}} /> */}
						<div className='row my-2'>
							<label className='form--label col-6'>
								How did you discover PrepAI?*
							</label>
							<div
								className='select-list col-6'
								style={{fontSize: "larger"}}
							>
								<select
									name='source_from'
									className='source_from custom-select'
									style={{width: "75%", height: "35px"}}
									required
									onChange={this.handleUpdateProfileChange}
									value={this.state.source_from}
									disabled={this.state.referralActive}
								>
									<option value=''>Select</option>

									{findUsList().map(op => {
										return (
											<option key={op} value={op}>
												{op}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						{
							this.state.source_from === "Others" && (
								// this.props.user.update_profile_index == 2 && (
								<>
									{/* <hr className="mb-2" style={{width: "15%"}}/> */}
									<div className='row my-2'>
										<label
											htmlFor='source_from_other'
											className='form--label col-6'
										>
											Please Specify*
										</label>
										<div className='col-6'>
											<input
												type='text'
												name='source_from_other'
												id='source_from_other'
												className='form-control'
												value={this.state.source_from_other}
												onChange={this.handleChange}
												required
												style={{width: "75%", height: "35px"}}
											/>
										</div>
									</div>
								</>
							)
							// )
						}
						{/* )} */}
						{/* {this.props.user.update_profile_index == 3 && ( */}
						{/* <hr className='my-2' style={{width: "15%"}} /> */}
						<div className='row my-2'>
							<label className='form--label col-6'>
								Update your contact number*
							</label>
							<div
								className='position-relative col-6'
								style={{fontSize: "larger"}}
							>
								<PhoneInput
									country={this.state.data.country_code}
									countryCodeEditable={false}
									placeholder='Enter phone number'
									name='formatted_phone_number'
									value={this.state.data.formatted_phone_number}
									onChange={this.handleOnChange}
									buttonStyle={{backgroundColor: "transparent"}}
									style={{width: "75%"}}
									className='complete-profile-popup'
								/>
							</div>
						</div>
						{/* )} */}
						<div
							style={{
								position: "relative",
								marginLeft: "98px",
								marginTop: "18px",
							}}
						></div>
					</form>
					{this.state.showLoader ? (
						<div className='spinner-border mt-3' role='status'>
							<span className='sr-only'>Loading...</span>
						</div>
					) : (
						""
					)}
        </SweetAlert>

        <TakeAssessment
          content_id={this.state.currentContentId}
          topic_name={this.state.currentTopicName}
          show={this.state.showDurationAlert}
          callBack={this.callbackFunc}
          history={this.props.history}
        />

        {/* For the case when less than required number of questions are generated */}
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.lessQuestionsGenerated}
          showCloseButton
          showConfirm={false}
          title={
            <div className="swal-title">
              <p className="style-text">
                Only {this.state.totalQuestionsFormed} questions generated. Add
                more content to generate the required number of questions.
              </p>
            </div>
          }
          onEscapeKey={() => this.setState({ lessQuestionsGenerated: false })}
          onOutsideClick={() =>
            this.setState({ lessQuestionsGenerated: false })
          }
          onConfirm={() => this.setState({ lessQuestionsGenerated: false })}
          onCancel={() => this.setState({ lessQuestionsGenerated: false })}
        />

        {/* For the case when more than required number of questions are generated */}
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.moreQuestionsGenerated}
          showConfirm={false}
          showCancel
          title={
            <div className="swal-title">
              <p className="style-text">
                {" "}
                Questions generated are more than requested{" "}
              </p>
            </div>
          }
          onEscapeKey={() => this.setState({ moreQuestionsGenerated: false })}
          onOutsideClick={() =>
            this.setState({ moreQuestionsGenerated: false })
          }
          onConfirm={() => this.setState({ moreQuestionsGenerated: false })}
          onCancel={() => this.setState({ moreQuestionsGenerated: false })}
        />

        <SweetAlert
          show={this.state.showAddOn}
          showCloseButton
          // confirmBtnBsStyle="success"
          confirmBtnCssClass="d-none"
          // title="Purchase Plan"
          confirmBtnText={`Proceed`}
          // disabled={!stripe}
          onEscapeKey={() => this.setState({ showAddOn: false })}
          onOutsideClick={() => this.setState({ showAddOn: false })}
          onCancel={() => this.setState({ showAddOn: false })}
        >
          <div style={{ padding: "20px" }}>
            <h4>Upgrade to access Premium add ons</h4>
            <div className="login--form">
              <div
                style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{ __html: this.state.addOnText }}
              ></div>

              <div>
                <button
                  onClick={() => this.displayStripe(this.state.activatePlan)}
                  type="button"
                  role="button"
                  className="preview--paper--btn"
                  style={{
                    width: "max-content",
                  }}
                  disabled={this.state.showLoader}
                >
                  {this.state.addOnCTAText}
                </button>
              </div>
              {/* {this.state.activatePlan.plan_name === "Free" && (
                <Link to="/plans" className="ff-tdd">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: this.state.freeAddOnSubText,
                    }}
                  ></span>
                </Link>
              )} */}
              {this.state.showLoader ? (
                <div className="spinner-border mt-3" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </SweetAlert>

        <SweetAlert
          show={this.state.showStripeForm}
          showCloseButton
          // confirmBtnBsStyle="success"
          confirmBtnCssClass="d-none"
          title="Pay Now"
          confirmBtnText={`Pay Now`}
          showConfirm={false}
          // disabled={!stripe}
          onEscapeKey={() => this.setState({ showStripeForm: false })}
          onOutsideClick={() => this.setState({ showStripeForm: false })}
          onCancel={() => this.setState({ showStripeForm: false })}
        >
          <div className="login--form">
            {this.state.stripeClientSecret && (
              <Elements
                options={{ clientSecret: this.state.stripeClientSecret }}
                stripe={this.state.stripePromise}
              >
                <InjectedCheckoutForm
                  {...this.props}
                  data={this.state.stripeClientSecret}
                />
              </Elements>
            )}
          </div>
        </SweetAlert>

        {this.state.showTopUp && (
          <QuestionPaperTopUp
            data={
              this.props.addOnsList["Question-Paper"][
                this.props.user.plan_details.plan_name
              ]
            }
            action={this.showTopUp}
            callBack={this.questionPaperTopUPCallBack}
          ></QuestionPaperTopUp>
        )}

        <ReferPopup />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    addOnsList: state.auth.addOnsList,
    showPopup: state.generalActions.showPopUp,
    defaultContent: state.generalActions.defaultContent,
    customQuesType: state.generalActions,
  };
};

export default connect(mapStateToProps)(SubjectExpertiseQuestions);
