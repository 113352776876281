import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { notifySuccess } from "../utils/Helper";
import { showLifetimeFomo } from "../store/reducers/generalActions";

class Salebar extends Component {
  state = {
    timeLeft: {},
    startSale: false,
    comingSoon: false,
    halloweenSale: false,
  };

  // Convert local time to specific timezone
  convertDateTime = (date) => {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      })
    );
  };

  calculateTimeLeft = () => {
    // Set Sale start date here
    var saleStartDate = new Date("Jul 1, 2023 9:29:59").getTime();

    saleStartDate = this.convertDateTime(saleStartDate);

    // Set the date we're counting down to(Sale end date)
    var countDownDate = new Date("Jul 2, 2023 9:29:59").getTime();
    // Convert local time to IST
    countDownDate = this.convertDateTime(countDownDate);

    // get today's date
    var today = new Date().getTime();
    today = this.convertDateTime(today);

    if (today >= saleStartDate) this.setState({ startSale: true });
    if (today >= countDownDate) this.setState({ startSale: false });
    // if (today < saleStartDate) this.setState({ comingSoon: true });

    // get the difference
    let diff = countDownDate - today;

    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: undefined };

    if (diff > 0) {
      timeLeft = {
        // math
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((diff % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  showHalloweenSale = () => {
    // Set Sale start date here
    let saleStartDate = new Date("Nov 1, 2023 00:00:01").getTime();

    // Convert local time to IST
    saleStartDate = this.convertDateTime(saleStartDate);

    // Set the date we're counting down to(Sale end date)
    let saleEndDate = new Date("Nov 7, 2023 11:59:59").getTime();

    // Convert local time to IST
    saleEndDate = this.convertDateTime(saleEndDate);

    // get today's date
    let today = new Date().getTime();
    today = this.convertDateTime(today);

    // return if sale started
    if (today >= saleStartDate && today < saleEndDate) return true;
    else return false;
  };

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({
        timeLeft: this.calculateTimeLeft(),
        halloweenSale: this.showHalloweenSale(),
      });
    }, 1000);
  }

  copyCoupon = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText("HALLOWEEN25");
    notifySuccess("Coupon Copied");
  };

  render() {
    return (
      <React.Fragment>
        {this.state.halloweenSale && (
          <div className="row p-0 sale_bar">
            <img
              style={{
                width: "100%",
                height: "10%",
              }}
              src={`${process.env.REACT_APP_PROJECT_URL}/img/HalloweenSale.png`}
              onClick={this.copyCoupon}
            />
            {/* <p className="halloween-sale">
              <span>USE CODE</span> <span className="coupon">HALLOWEEN25</span>{" "}
              <span>AT CHECKOUT</span>
            </p> */}
          </div>
        )}
        {(this.state.comingSoon || this.state.startSale) && (
          <div className="row sale_bar">
            <div className="d-flex" style={{ gap: "1rem" }}>
              <img
                className="bunny-img"
                src={`${process.env.REACT_APP_PROJECT_URL}/img/american-flag.png`}
              />
              {this.state.startSale ? (
                <p className="sale-text">
                  4th of July Sale is live now! Up to{" "}
                  <span style={{ color: "#59fdd7", fontWeight: "500" }}>
                    40% off
                  </span>{" "}
                  on all PrepAI Premium Plans
                </p>
              ) : (
                <p style={{ left: "inherit" }} className="sale-text">
                  Get ready for the Special Easter Sale.{" "}
                  <span style={{ color: "pink", fontWeight: "500" }}>
                    Coming Soon!
                  </span>
                </p>
              )}
            </div>
            {this.state.timeLeft.seconds != undefined &&
              this.state.startSale && (
                <div className="time-frame">
                  <div className="ends-in">
                    <span style={{ color: "#59fdd7", fontWeight: "500" }}>
                      Ends in
                    </span>
                    <div className="startin">
                      <div className="d-flex flex-column wt-25">
                        <span className="ft-14-fw-500">
                          {this.state.timeLeft.days}
                        </span>
                        <span className="ft-11 text-white">DAYS</span>
                      </div>
                      <div className="d-flex flex-column wt-25">
                        <span className="ft-14-fw-500">
                          {this.state.timeLeft.hours}
                        </span>
                        <span className="ft-11 text-white">HOURS</span>
                      </div>
                      <div className="d-flex flex-column wt-25">
                        <span className="ft-14-fw-500">
                          {this.state.timeLeft.minutes}
                        </span>
                        <span className="ft-11 text-white">MINUTES</span>
                      </div>
                      <div className="d-flex flex-column wt-25">
                        <span className="ft-14-fw-500">
                          {this.state.timeLeft.seconds}
                        </span>
                        <span className="ft-11 text-white">SECONDS</span>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <Link to="/plans" style={{ textDecoration: "none" }}>
                      <p className="grab_deal_now">{"Get the deal >>"}</p>
                    </Link>
                  </div>
                </div>
              )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

class LifetimePlanTimerComponent extends Component {
  state = {
    timeLeft: {},
    startSale: false,
  };
  // Convert local time to specific timezone
  convertDateTime = (date) => {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      })
    );
  };

  calculateTimeLeft = () => {
    // Set Sale start date here
    const now = Number(sessionStorage.getItem("lifetime_timer"));
    const futureTime = Number(sessionStorage.getItem("lifetime_timer_till"));

    if (!now || now == NaN) {
      this.setState({ startSale: false });
      if(this.props.showLifetimeFomo) this.props.dispatch(showLifetimeFomo(false));
      clearInterval(this.timer)
      return 0;
    }
    if (!futureTime || futureTime == NaN) {
      this.setState({ startSale: false });
      if(this.props.showLifetimeFomo) this.props.dispatch(showLifetimeFomo(false));
      clearInterval(this.timer)
      return 0;
    }

    // Convert local time to IST
    let saleStartDate = this.convertDateTime(now);

    // Set the date we're counting down to(Sale end date)
    // Convert local time to IST
    let countDownDate = this.convertDateTime(futureTime);

    // get today's date
    var today = new Date().getTime();
    today = this.convertDateTime(today);

    if (today >= saleStartDate) {
      this.setState({ startSale: true })
      this.props.dispatch(showLifetimeFomo(true));
    };
    if (today >= countDownDate) {
      this.setState({ startSale: false })
      if(this.props.showLifetimeFomo) this.props.dispatch(showLifetimeFomo(false));
      clearInterval(this.timer)
    };
    // if (today < saleStartDate) this.setState({ comingSoon: true });

    // get the difference
    let diff = countDownDate - today;

    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: undefined };

    if (diff > 0) {
      timeLeft = {
        // math
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((diff % (1000 * 60)) / 1000),
      };
    } else {
      clearInterval(this.timer)
      if(this.props.showLifetimeFomo) this.props.dispatch(showLifetimeFomo(false));
    }
    return timeLeft;
  };

  componentDidMount() {
    const now = Number(sessionStorage.getItem("lifetime_timer"));
    const futureTime = Number(sessionStorage.getItem("lifetime_timer_till"));

    if (!now || now == NaN) {
      this.setState({ startSale: false });
      return;
    }
    if (!futureTime || futureTime == NaN) {
      this.setState({ startSale: false });
      return;
    }

    this.timer = setInterval(() => {
      // update the value of timer
      let timeLeft = this.calculateTimeLeft();
      this.setState({
        timeLeft: timeLeft,
        startSale: true,
      });
    }, 1000);
  }

  render() {
    return (
      <React.Fragment>
        {this.state.startSale &&
          this.state.timeLeft.seconds != undefined &&
          this.state.startSale && (
            <div className="d-flex" style={{gap: '5px'}}>
              <div className="d-flex">
                <span className="">{this.state.timeLeft.minutes}</span>
                <span className="">M</span>
              </div>
              <div className="d-flex">
                <span className="">{this.state.timeLeft.seconds}</span>
                <span className="">S</span>
              </div>
            </div>
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showLifetimeFomo: state.generalActions.showLifetimeFomo,
    user: state.auth.user,
  };
};

export default Salebar;
export const LifetimePlanTimer = connect(mapStateToProps)(
  LifetimePlanTimerComponent
);
