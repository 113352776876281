import Http from "./utils/Http";
import { setUserData, authLogout, getAddOns } from "./store/reducers/auth";
import { notifyError } from "./utils/Helper";

/**
 * fetch the current logged in user
 *
 * @returns {function(*)}
 */

export function unsubscribe(params) {
  return Http.post("unsubscribe", params);
}

export function loginUser(params) {
  return Http.post("login", params);
}

export function referUser(params) {
  return Http.post("referuser", params);
}

export function getReferredBalance(params) {
  return Http.get("getReferralBalance", params);
}

export function registerUser(params) {
  return Http.post("registration", params);
}

export function changePassword(params) {
  return Http.post("changePassword", params);
}

export function forgotPasswordUser(params) {
  return Http.post("forgetPassword", params);
}

export function checkValidToken(params) {
  return Http.post("checkTokenAvailability", params);
}

export function resetPassword(params) {
  return Http.post("resetPassword", params);
}

export function updateProfile(params) {
  return Http.post("updateProfile", params);
}

export function deleteProfile(params) {
  return Http.post("deleteProfile", params);
}

export function fetchUser() {
  return (dispatch) => {
    return Http.get("me")
      .then((res) => {
        if (res.data.code === 404) {
          dispatch(authLogout());
        }
        const data = res.data.response;
        dispatch(setUserData(data));
        if (!data?.checklist_steps.includes(0)) {
          sessionStorage.setItem('show_initial_text', true);
        }
        else{
          sessionStorage.removeItem('show_initial_text');
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          const { data } = err.response;
          notifyError(data.msg);
          dispatch(authLogout());
        }
        if (err.response && err.response.status === 401) {
          const { data } = err.response;
          notifyError(data.msg);
          dispatch(authLogout());
        }
        // console.log(err);
      });
  };
}

export function getAddOnsList() {
  return (dispatch) => {
    return Http.get("addOnsList")
      .then((res) => {
        const data = res.data.response;
        // console.log("data", data);
        dispatch(getAddOns(data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          const { data } = err.response;
          notifyError(data.msg);
          dispatch(authLogout());
        }
        if (err.response && err.response.status === 401) {
          const { data } = err.response;
          notifyError(data.msg);
          dispatch(authLogout());
        }
        // console.log(err);
      });
  };
}

export function generateQuestion(params) {
  return Http.post("generateQuestions", params);
  // return Http.post("generateQuestions", params, {
  //   headers: {
  //     "content-type": "multipart/form-data",
  //   },
  // });
}

export function updateQuestionRating(params) {
  return Http.post("storeQuestionRating", params);
}

export function updateOptionRating(params) {
  return Http.post("storeOptionRating", params);
}

export function updateIsRequired(params) {
  return Http.post("storeIsRequired", params);
}

export function uploadFile(params) {
  return Http.post("Chat/SendFiles", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function generateQuestionFromVideoP1(params) {
  return Http.post("getTranscriptsP1", params);
}

export function generateQuestionFromVideo(params) {
  return Http.post("getTranscripts", params);
}

export function btGenerateQuestionFromVideo(params) {
  return Http.post("btGenerateQuestionsVideo", params);
}

export function wikiP1(params) {
  return Http.post("wikiP1", params);
}

export function wikiTopicSuggestions(params) {
  return Http.post("wiki", params);
}

export function getMyQuestionPaper(params) {
  return Http.post("getMyQuestionPaper", params);
}

export function previewQuestions(params) {
  return Http.post("previewQuestions", params);
}

export function btPreviewQuestions(params) {
  return Http.post("btPreviewQuestions", params);
}

export function downloadQuestionPaper(params) {
  return Http.post("downloadQuestionPaper", params, {
    responseType: "blob",
  });
}

export function seDownloadQuestionPaper(params) {
  return Http.post("seDownloadQuestionPaper", params, {
    responseType: "blob",
  });
}

export function btDownloadQuestionPaper(params) {
  return Http.post("btDownloadQuestionPaper", params, {
    responseType: "blob",
  });
}

export function finalizeQuestions(params) {
  return Http.post("finalizeQuestions", params);
}

export function editQuestionPaper(params) {
  return Http.post("editQuestions", params);
}

export function getPlansList() {
  return Http.get("planList");
}

export function addOnsList() {
  return Http.get("addOnsList");
}

export function getCountry() {
  return Http.get("getCountry");
}

export function getUserPlanHistory() {
  return Http.get("userPlanHistory");
}

export function getCouponsAndOffers() {
  return Http.get("getCouponsAndOffers");
}

export function generateOrderIdRazorpay(params) {
  return Http.post("generateOrderId", params);
}

export function verifyPaymentRazorpay(params) {
  return Http.post("verifyPayment", params);
}

export function verifyAccountCode(params) {
  return Http.post("verifyCode", params);
}

export function verifyResendAccountCode() {
  return Http.get("resendCode");
}

export function updateInstructionRead() {
  return Http.post("instructionReaded");
}

export function generateQuestionsFromPdfDocP1(params) {
  return Http.post("generateQuestionsFromPdfDocP1", params);
}

export function generateQuestionFromPdfDoc(params) {
  return Http.post("generateQuestionsFromPdfDoc", params);
}

export function btGenerateQuestionFromPdfDoc(params) {
  return Http.post("GenerateQuestionDocBloomTaxanomy", params);
}

export function getQPRating(params) {
  return Http.get("getQPRating");
}

export function createAppApi() {
  return Http.post("createAppApi");
}

export function listAppApi() {
  return Http.get("listAppApi");
}

export function refreshClientSecretApi(params) {
  return Http.put("refreshClientSecretApi", params);
}

export function deleteAppApi(params) {
  return Http.post("deleteAppApi", params);
}

export function QuestionPaperRating(params) {
  return Http.post("questionPaperRating", params);
}

export function EnterpriseEnquiry(params) {
  return Http.post("enterpriseEnquiry", params);
}

export function SaveEditQuestion(params) {
  return Http.post("saveEditQuestion", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function getNotificationList() {
  return Http.get("getNotificationList");
}

export function validationForDownloadQuePaper(params) {
  return Http.post("validationForDownloadQuePaper", params);
}

export function createPaymentIntent(params) {
  return Http.post("createPaymentIntent", params);
}

export function setPaymentIntent(params) {
  return Http.post("setPaymentIntent", params);
}

export function createSubscription(params) {
  return Http.post("createSubscription", params);
}

export function addPagination(params) {
  return Http.post("pagination", params);
}

export function addQuestion(params) {
  return Http.post("addQuestion", params);
}

export function cancelSubscription(params) {
  return Http.post("cancelSubscription", params);
}

export function pauseSubscription(params) {
  return Http.post("pauseSubscription", params);
}

export function discountSubscription(params) {
  return Http.post("addDiscount", params);
}

export function listSubscription() {
  return Http.get("listSubscriptions");
}

export function storeBilllingAddress(params) {
  return Http.post("storeBilllingAddress", params);
}

export function getFeatureRequests() {
  return Http.get("feature-requests");
}

export function addFeatureRequest(params) {
  return Http.post("feature-requests", params);
}

export function updateVote(params) {
  return Http.post("votes", params);
}

export function getVotes(id) {
  return Http.get(`votes?id=${id}`);
}

export function registerExternalUser(params) {
  return Http.post("registerExternalUser", params);
}

export function verifyExternalUser(params) {
  return Http.post("verifyExternalUser", params);
}

export function getAnswerKeys(id) {
  return Http.get(`getAnswerKey?content_id=${id}`, {
    responseType: "blob",
  });
}

export function getBtAnswerKeys(id) {
  return Http.get(`bt-answer-key?content_id=${id}`, {
    responseType: "blob",
  });
}

export function storeStripePaymentData(params) {
  return Http.post("storeStripePaymentData", params);
}

export function activatePlan(params) {
  return Http.post("activatePlan", params);
}

export function scheduleCallStatus(parms = true) {
  if (parms) {
    return Http.get("scheduleCallStatus");
  } else {
    return Http.post("scheduleCallStatus");
  }
}

export function updateUserDetails(params) {
  return Http.post("updateUserDetails", params);
}

export function btUpdateToken() {
  return Http.get("btUpdateToken");
}

export function progress(params) {
  return Http.post("progress", params);
}

export function generateQuestionP1(params) {
  // return Http.post("generateQuestions", params);
  return Http.post("generateQuestionsP1", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function addProcessToBackground(params) {
  return Http.post("addProcessToBackground", params);
}

export function getRequestsInQueue() {
  return Http.get("getQueueRequestCount");
}

export function checkCouponAvailability(params) {
  return Http.post("checkCouponAvailability", params);
}

export function deleteQuestionPaper(params) {
  return Http.post("deleteQuestionPaper", params);
}

export function submitTest(params) {
  return Http.post("submitTest", params);
}

export function submitAssessment(params) {
  return Http.post("submitAssessment", params);
}

export function updateChecklistStatus(params) {
  return Http.post("updateChecklistStatus", params);
}

export function testPreview(params) {
  return Http.post("testPreview", params);
}

export function responsePreview(params) {
  return Http.post("responsePreview", params);
}

export function getTestReport(params) {
  return Http.post("getTestReport", params);
}

export function createTest(params) {
  return Http.post("createTest", params);
}

export function createAssessment(params) {
  return Http.post("createAssessment", params);
}

export function shareTest(params) {
  return Http.post("shareTest", params);
}

export function verifyTestUser(params) {
  return Http.post("verifyTestUser", params);
}

export function sendTestCode(params) {
  return Http.post("sendTestCode", params);
}

export function finalizeTest(params) {
  return Http.post("finalizeTest", params);
}

export function getTest(params) {
  return Http.post("getTest", params);
}

export function finalizeSelfAssessment(params) {
  return Http.post("finalizeSelfAssessment", params);
}

export function getAssessment(params) {
  return Http.post("getAssessment", params);
}

export function getTestUser() {
  return Http.get("getTestUser");
}

export function checkTestAvailability(params) {
  return Http.post("checkTestAvailability", params);
}

export function getMyTests(params) {
  return Http.post("getMyTests", params);
}

export function getMyAssessment(params) {
  return Http.post("getMyAssessment", params);
}

export function getTestScores(params) {
  return Http.post("getTestScores", params);
}

export function GQBloomTaxanomy(params) {
  return Http.post("GQBloomTaxanomy", params);
}

export function GQSubjectExpertise(params) {
  return Http.post("gqSubjectExpertise", params);
}

export function GQSearchTopic(params) {
  return Http.post("btSearchTopic", params);
}

export function BuyCredits(params) {
  return Http.post("buyCredits", params);
}

export function createSets(params) {
  return Http.post("createSets", params);
}

export function createSetsBloom(params) {
  return Http.post("createSetsBloom", params);
}

export function createSetsExpertise(params) {
  return Http.post("createSetsExpertise", params);
}
export function getSeatsCount() {
  return Http.get("getFilledSeats");
}

export function inviteEnterpriseMembers(params) {
  return Http.post("inviteEnterpriseMembers", params);
}

export function getEnterpriseUsers() {
  return Http.get("getEnterpriseUsers");
}

export function getEnterpriseReports(params) {
  return Http.post("getEnterpriseReports", params);
}

export function getMostActiveUsers(params) {
  return Http.post("getMostActiveUsers", params);
}

export function getDaywiseUsersReports(params) {
  return Http.post("getDayWiseReportUser", params);
}

export function disableUser(params) {
  return Http.post("disableUser", params);
}

export function enableUser(params) {
  return Http.post("enableUser", params);
}

export function editMember(params) {
  return Http.post("editMember", params);
}

export function getMember(params) {
  return Http.post("getMember", params);
}

export function purchaseAddOns(params) {
  return Http.post("purchaseAddOns", params);
}

export function activateAddOns(params) {
  return Http.post("activateAddOns", params);
}

export function purchaseFreePlan(params) {
  return Http.post("purchaseFreePlan", params);
}

export function getUserReports(params) {
  return Http.post("getUserReports", params);
}
